import { createTheme } from '@mui/material'

const defaultTheme = createTheme({
  palette: {
    primary: {
      main: '#000000',
      light: '#3c44b126',
      dark: '#141625',
    },
    secondary: {
      main: 'rgb(201, 23, 23)',
      light: 'rgba(201, 23, 23, 0.8)',
      dark: 'ash',
    },
    background: {
      default: '#FFFFFF',
    },
  },
  overrides: {
    MuiBox: {
      root: {
        backgroundColor: '#FFFFFF',
      },
    },
    MuiTable: {
      root: {
        backgroundColor: '#141625',
      },
    },
    MuiAppBar: {
      root: {
        transform: 'translateZ(0)',
      },
    },
  },
  props: {
    MuiIconButton: {
      disableRipple: true,
    },
  },
})

export default defaultTheme
