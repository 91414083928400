import React from 'react'
import { useStateValue } from '../store/StateProvider'
import UserClasses from './user/UserClasses'
import DashboardLayout from '../layouts/DashboardLayout'
import CoachClasses from './coach/CoachClasses'

function Classes() {
  const { user } = useStateValue()[0]
  return (
    <DashboardLayout>
      {user.role === 'coach' ? <CoachClasses /> : <UserClasses />}
    </DashboardLayout>
  )
}

export default Classes
