import { useQuery } from 'react-query'
import axiosInstance from '../../lib/axiosInstance'
import { toast } from 'react-toastify'

/**
 * @param {string} url path of the request
 * @param {string} queryKey cache key for the query
 * @param {boolean} enabled controller for the query
 *
 * @author MAHADI
 * @dateCreated 28/10/2023
 * @description Hook for runnig get requests
 *
 * @returns {{data: object | object[], isFetching:boolean,
 * isLoading: boolean, isError:boolean,
 * isSuccess: boolean, refetch: (void)=>void,
 * isRefetching: boolean, isLoadingError: boolean, isRefetchError: boolean }} Object with response result
 *
 */
export const useGET = (url, queryKey, enabled = true) => {
  const fetch = async () => {
    try {
      const response = await axiosInstance.get(url)
      return response.data?.data
    } catch (err) {
      toast.error(err.message)
      console.error(err.message, '\n', err)
      return []
    }
  }

  const {
    data,
    isFetching,
    isLoading,
    isError,
    isSuccess,
    refetch,
    isRefetching,
    isLoadingError,
    isRefetchError,
  } = useQuery({
    queryKey: queryKey,
    queryFn: fetch,
    enabled: enabled,
    refetchOnWindowFocus: 'false',
    refetchOnReconnect: 'false',
  })
  return {
    data,
    isFetching,
    isLoading,
    isError,
    isSuccess,
    refetch,
    isRefetching,
    isLoadingError,
    isRefetchError,
  }
}

