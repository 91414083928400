import React from 'react'
import { Box } from '@mui/material'
import { Form, useForm } from '../../hooks/useForm'
import Controls from '../controls/Controls'
import usePOST from '../../hooks/api/usePOST.hook'
import { usePATCH } from '../../hooks/api/usePATCH.hook'

const newForm = {
  name: '',
  description: '',
  price: 1,
  category: '',
  active: false,
  image: '',
}

function ProductForm({
  product = undefined,
  errorCallback = () => {},
  successCallback = () => {},
}) {
  const validate = (fields = values) => {
    const temp = { ...errors }
    ;['description', 'name', 'price', 'image'].forEach((required) => {
      if (required in fields)
        temp[required] = fields[required] ? '' : `${required} is required`
    })

    setErrors({ ...temp })
    if (fields === values) return Object.values(temp).every((x) => x === '')
  }
  const [values, resetForm, errors, setErrors, changeFunction] = useForm(
    product ? product : newForm,
    validate,
    true
  )
  const {
    mutate: create,
    isLoading: createLoading,
    isError: isCreateError,
    error: createError,
  } = usePOST('products', (data) => {
    if (!isCreateError) {
      successCallback()
      resetForm()
    } else {
      errorCallback(createError)
    }
  })

  const {
    mutate: update,
    isLoading: updateLoading,
    isError: isUpdateError,
    error: updateError,
  } = usePATCH(`products/${product?.id}`, (data) => {
    if (!isUpdateError) {
      successCallback()
      resetForm()
    } else {
      errorCallback(updateError)
    }
  })

  const handleSubmit = () => {
    if (!validate(values)) return
    const data = values
    if (product) {
      update(data)
    } else {
      create(data)
    }
  }
  return (
    <Form onSubmit={handleSubmit}>
      <Controls.Input
        label={'Name'}
        name={'name'}
        value={values.name}
        changeFxn={changeFunction}
        error={errors.name}
      />
      <Controls.Input
        label={'Description'}
        name={'description'}
        rows={3}
        multiline
        value={values.description}
        changeFxn={changeFunction}
        error={errors.description}
      />
      <Controls.Input
        name={'price'}
        type='number'
        label={'Price'}
        error={errors.price}
        value={values.price}
        changeFxn={changeFunction}
      />
      <Controls.Input
        name={'category'}
        label={'category'}
        error={errors.category}
        value={values.category}
        changeFxn={changeFunction}
      />
      <Controls.CheckBox
        changeFunction={changeFunction}
        label={'Available'}
        name={'active'}
        value={values.active}
      />
      <Box display='flex' gap={2}>
        <Controls.Button
          disabled={createLoading | updateLoading}
          variant={''}
          text={
            product
              ? updateLoading
                ? 'updating..'
                : 'update'
              : createLoading
                ? 'creating..'
                : 'create'
          }
          type='submit'
        />
        {/* <Controls.Button /> */}
      </Box>
    </Form>
  )
}

export default ProductForm
