import React from 'react'
import { Navigate } from 'react-router-dom'
import { useStateValue } from '../store/StateProvider'

function ProtectedRoute({ children, restrictedTo = [], redirect = '/' }) {
  const { user } = useStateValue()[0]

  if (!user) {
    return <Navigate to='/' replace={true} />
  }

  if (restrictedTo.length && !restrictedTo.includes(user?.role)) {
    return <Navigate to={redirect} replace={true} />
  }

  return <>{children}</>
}

export default ProtectedRoute
