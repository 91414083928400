import { cleanDate } from '../lib/utils'

export const initialState = {
  /**
   * @type {{
   * id:string, name:string, price:number, start:Date,
   * coach:string,
   * end:Date, noOfSlots:number, reservations:number[]}[]
   * }
   */
  classes: [],
  refetchClasses: () => {},
  userDetails: {},
  products: [],
  classesLoaded: null,
  currentInvoice: null,
  invoiceModal: null,
  searched: [],
  reports: [],
  modal: null,
  showDrawer: false,
  coaches: [],
  /**
   * @type {{
   * id:string ,
   * email:string ,
   * name:string ,
   * description:string ,
   * passes:number ,
   * role:string ,
   * image:string ,
   * cart:{product:string, quantity:number}[]}
   * }}
   */
  user: null,
  userType: null,
  token: null,
  drawerWidth: 0,
  reservations: [],
}

const updateFunction = (collection, modified, isDelete = false) => {
  const index = collection.findIndex((ses) => ses.id === modified?.id)
  const updated = [...collection]
  if (isDelete) updated.splice(index, 1)
  else updated[index] = modified
  return updated
}

/**
 *
 * @param {typeof initialState} state
 * @param {{type:string, data: any}} action
 * @returns
 */
export const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_CLASSES':
      return {
        ...state,
        classes: (action.data || []).map((c) => ({
          ...c,
          start: cleanDate(c.start),
          end: cleanDate(c.end),
        })),
      }
    case 'UPDATE_CLASS':
      return {
        ...state,
        classes: updateFunction(state.classes, action.data),
      }
    case 'DELETE_CLASS':
      return {
        ...state,
        classes: updateFunction(state.classes, { id: action.data }, true),
      }
    case 'ADD_CLASS':
      !state.classes.find((i) => i.id === action.data?.id) &&
        state.classes.splice(0, 0, action.data)
      return state

    case 'SET_CURRENT_CLASS':
      return {
        ...state,
        currentInvoice:
          state.classes.find((inv) => inv.id === action.data) ||
          state.searched.find((inv) => inv.id === action.data),
      }

    case 'SET_SEARCHED': {
      return {
        ...state,
        searched: action.data,
      }
    }
    case 'SET_REPORTS': {
      return {
        ...state,
        reports: action.data,
      }
    }
    case 'SET_PRODUCTS':
      return {
        ...state,
        products: action.data,
      }
    case 'UPDATE_PRODUCT':
      return {
        ...state,
        products: updateFunction(state.products, action.data),
      }
    case 'DELETE_PRODUCT':
      return {
        ...state,
        products: updateFunction(state.products, { id: action.data }, true),
      }
    case 'ADD_PRODUCT':
      !state.products.find((p) => p.id === action.data?.id) &&
        state.products.splice(0, 0, action.data)
      return state
    case 'SET_COACHES':
      return {
        ...state,
        coaches: action.data,
      }
    case 'UPDATE_COACH':
      return {
        ...state,
        coaches: updateFunction(state.coaches, action.data),
      }
    case 'DELETE_COACH':
      return {
        ...state,
        coaches: updateFunction(state.coaches, { id: action.data }, true),
      }
    case 'ADD_COACH':
      !state.coaches.find((s) => s.id === action.data?.id) &&
        state.coaches.splice(0, 0, action.data)
      return state
    case 'TOGGLE_CLASS_MODAL':
      return {
        ...state,
        invoiceModal: !state.invoiceModal,
      }
    case 'TOGGLE_MODAL':
      return {
        ...state,
        modal: !state.modal,
      }
    case 'SET_CLASSES_LOADED':
      return { ...state, classesLoaded: true }
    case 'SET_USER':
      return {
        ...state,
        user: action.data?.user || action.data,
        userDetails: action.data || {},
      }
    case 'SET_USER_TYPE':
      return {
        ...state,
        userType: action.data,
      }
    case 'UPDATE_USER': {
      return {
        ...state,
        user: { ...(state.user || {}), ...action.data },
      }
    }
    case 'SET_TOKEN':
      return {
        ...state,
        token: action.data,
      }
    case 'TOGGLE_DRAWER':
      return {
        ...state,
        showDrawer: !state.showDrawer,
      }
    case 'SET_DRAWER_WIDTH':
      return {
        ...state,
        drawerWidth: action.data,
      }
    case 'LOGOUT':
      return {
        ...state,
        token: null,
        user: null,
      }
    case 'SET_REFETCH_CLASSES':
      return {
        ...state,
        refetchClasses: action.data,
      }
    case 'RESERVE_CLASS':
      const user = state.user
      user.passes -= 1
      state.userDetails = {
        ...state.userDetails,
        reservations: [...state.userDetails.reservations, action.data],
      }
      return {
        ...state,
        user,
      }
    default:
      console.error(`Action ${action.type} not Implemented`)
      return state
  }
}
