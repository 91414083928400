import { Box, TableBody, TableCell, TableRow } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Controls from '../../components/controls/Controls'
import { Search } from '@mui/icons-material'
import useTable from '../../hooks/useTable'
import DashboardLayout from '../../layouts/DashboardLayout'
import { useGET } from '../../hooks/api/useGET.hook'
import Loading from '../../components/loading/Loading'
import ProtectedRoute from '../../components/ProtectedRoute'

const headCells = [
  { id: 'email', label: 'Email' },
  { id: 'name', label: 'Name' },
  { id: 'role', label: 'Role' },
  { id: 'passes', label: 'Passes' },
]

function Users() {
  const [users, setUsers] = useState([])

  const { data, isLoading } = useGET('users', 'getAllUsers')
  useEffect(() => {
    setUsers(data || [])
  }, [data])

  const [filter, setFilter] = useState({ fn: (items) => items })

  const handleSearch = (e) => {
    let query = e.target.value?.toLowerCase()
    setFilter({
      fn: (items) =>
        query
          ? items.filter(
              (item) =>
                item.name?.toLowerCase().includes(query) ||
                item.room?.toLowerCase().includes(query)
            )
          : items,
    })
  }

  const { TableContainer, TblHead, TblPagination, recordsAfterPagination } =
    useTable(users, headCells, filter)
  return (
    <ProtectedRoute restrictedTo={['admin', 'coach']}>
      <DashboardLayout>
        <Box p={2}>
          <Box display={'flex'} gap={2} width={'100%'}>
            <Controls.Input
              label={'Search Users'}
              changeFxn={handleSearch}
              InputProps={{ startAdornment: <Search /> }}
              style={{ flex: '1', maxWidth: '530px' }}
            />
          </Box>
          {isLoading && <Loading />}
          <Box mt={2} p={2}>
            <TableContainer>
              <TblHead />
              <TableBody>
                {recordsAfterPagination().map((e, i) => (
                  <TableRow key={i + 1}>
                    <TableCell>{e.email}</TableCell>
                    <TableCell>{e.name}</TableCell>
                    <TableCell>{e.role}</TableCell>
                    <TableCell>{e.passes}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </TableContainer>
            <TblPagination />
          </Box>
        </Box>
      </DashboardLayout>
    </ProtectedRoute>
  )
}

export default Users
