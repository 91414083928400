import emailjs from 'emailjs-com'
import { cleanDate } from './utils.js'

export const EmailTemplates = Object.freeze({
  WELCOME: 'template_4btmr7p',
  RESERVATION_NOTICE: 'reservation_notice',
  ORDER_CREATED: 'order_created',
  ORDER_COMPLETED: 'order_completed',
  PASS_BOUGHT: 'pass_bought',
})

/**
 * @author MAHADI
 * @dateCreated 0125/2024
 * @description Class That Handles Email Sending Using EmailJs
 */
export default class Email {
  /**
   * Service Id
   */
  static #SERVICE_ID = process.env.REACT_APP_EMAILJS_SERVICE_ID
  /**
   * Public Key
   */
  static #PUBLIC_KEY = process.env.REACT_APP_EMAILJS_PUBLIC_KEY

  /**
   * Uses Email js to send emails
   * @param {string} templateId Id of the template to send email with
   * @param {object} templateParams Template variables
   * @return {any} response
   */
  static async #send(templateId, templateParams) {
    try {
      const response = await emailjs.send(
        this.#SERVICE_ID,
        templateId,
        templateParams,
        this.#PUBLIC_KEY
      )
      console.log('Email sent successfully:', response)
      return response
    } catch (error) {
      console.error('Error sending email:', error)
      return null
    }
  }

  /**
   * Sends Welcome Email message
   * @param {User} user recipient email
   *
   * @return {Promise<object>} response
   */
  static async sendWelcome(user) {
    return await this.#send(EmailTemplates.WELCOME, {
      email: user.email,
      name: user.name,
    })
  }
  /**
   * Sends Welcome Email message
   * @param {User} user recipient email
   * @param {ReservationHistory} reservation document
   * @return {Promise<object>} response
   *
   */
  static async sendReservationNotice(user, reservation) {
    return await this.#send(EmailTemplates.RESERVATION_NOTICE, {
      email: user.email,
      name: user.name,
      className: reservation.yClass.title,
      start: cleanDate(reservation.yClass.start),
      end: cleanDate(reservation.yClass.end),
      bed: reservation.bed,
    })
  }

  /**
   * Sends Welcome Email message
   * @param {User} user recipient email
   * @param {Order} order order doc
   *
   * @return {Promise<object>} response
   */
  static async sendOrderCreatedNotice(user, order) {
    return await this.#send(EmailTemplates.ORDER_CREATED, {
      email: user.email,
      name: user.name,
      orderNo: order.id,
      amount: order.amount,
      noOfProducts: order.products.length,
      products: order.products,
      status: order.status,
    })
  }

  /**
   * Sends Welcome Email message
   * @param {User} user recipient email
   * @param {Order} order order doc
   *
   * @return {Promise<object>} response
   */
  static async sendOrderCompletedNotice(user, order) {
    return await this.#send(EmailTemplates.ORDER_COMPLETED, {
      email: user.email,
      name: user.name,
      orderNo: order.id,
      amount: order.amount,
      noOfProducts: order.products.length,
      products: order.products,
      status: order.status,
    })
  }

  /**
   * Sends Welcome Email message
   * @param {User} user recipient email
   * @param {PassType} pass order doc
   *
   * @return {Promise<object>} response
   */
  static async sendBuyPassNotice(user, pass) {
    return await this.#send(EmailTemplates.ORDER_COMPLETED, {
      email: user.email,
      name: user.name,
      passName: pass.name,
      usages: pass.usages,
      currentPass: user.passes,
    })
  }
}

