import React, { useEffect, useState } from 'react'
import CartItem from './CartItem'
import { useStateValue } from '../../../store/StateProvider'
import { Typography } from '@mui/material'
import { toCurrency } from '../../../lib/utils'

function CartItemsList({ loading, withEdit = true, saveCart }) {
  const { user, products } = useStateValue()[0]
  const [cart, setCart] = useState([])

  useEffect(() => {
    const temp = []
    for (const p of products) {
      const found = user?.cart?.find((c) => c.product === p.id)
      if (found) temp.push({ ...p, quantity: found.quantity })
    }
    setCart(temp)
  }, [user, products])

  const updateCartItem = (item) => {
    let newCart = user?.cart || []
    newCart = newCart.filter((c) => c.product !== item.id)
    return {
      remove: () => saveCart({ cart: newCart }),
      addQuantity: () => {
        item.quantity++
        newCart.push({
          product: item.id,
          quantity: item.quantity,
        })
        saveCart({ cart: newCart })
      },
      reduceQuantity: () => {
        item.quantity--
        if (item.quantity > 0)
          newCart.push({
            product: item.id,
            quantity: item.quantity,
          })
        saveCart({ cart: newCart })
      },
    }
  }
  return (
    <>
      <div className='w-full flex gap-3 flex-wrap justify-around' spacing={3}>
        {cart.map((item) => (
          <div className='max-w-[300px]' key={item.id}>
            {/* <CartItem item={item} onUpdateCartQty={onUpdateCartQty} onRemoveFromCart={onRemoveFromCart} /> */}
            <CartItem
              loading={loading}
              updateCartItem={updateCartItem}
              withEdit={withEdit}
              item={item}
            />
          </div>
        ))}
      </div>
      <Typography variant='h4' width={'100%'} textAlign={'right'} my={2}>
        Total:{' '}
        {toCurrency(
          cart.reduce((sub, item) => item.price * item.quantity + sub, 0)
        )}
      </Typography>
    </>
  )
}

export default CartItemsList
