import {
  Box,
  Grid,
  IconButton,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
} from '@mui/material'
import React, { useState } from 'react'
import Controls from '../../components/controls/Controls'
import { Search } from '@mui/icons-material'
import AddYogaClass from '../../components/modals/AddYogaClass'
import useTable from '../../hooks/useTable'
import { useStateValue } from '../../store/StateProvider'
import { calculateTimeDifference, cleanDate } from '../../lib/utils'
import UpdateYogaClass from '../../components/modals/UpdateYogaClass'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import { Link } from 'react-router-dom'
import DashboardLayout from '../../layouts/DashboardLayout'

const headCells = [
  { id: 'name', label: 'Class Name' },
  { id: 'room', label: 'Room Type' },
  { id: 'coach', label: 'Coach' },
  { id: 'duration', label: 'Class Duration' },
  { id: 'end', label: 'Status' },
  { id: 'actions', label: 'Actions', disableSort: true },
]

function AdminClasses() {
  const { classes, coaches } = useStateValue()[0]
  const [filter, setFilter] = useState({ fn: (items) => items })
  const coachName = (id) => coaches.find((c) => c.id === id)?.name

  const classStatus = (c) =>
    cleanDate(c.end).getTime() <= Date.now() ? 'closed' : 'active'

  const handleSearch = (e) => {
    let query = e.target.value?.toLowerCase()
    setFilter({
      fn: (items) =>
        query
          ? items.filter(
              (item) =>
                item.name?.toLowerCase().includes(query) ||
                item.room?.toLowerCase().includes(query)
            )
          : items,
    })
  }

  const { TableContainer, TblHead, TblPagination, recordsAfterPagination } =
    useTable(classes, headCells, filter)
  return (
    <DashboardLayout>
      <Box p={2}>
        <Box display={'flex'} gap={2} width={'100%'}>
          <Controls.Input
            label={'Search Classes'}
            changeFxn={handleSearch}
            InputProps={{ startAdornment: <Search /> }}
            style={{ flex: '1', maxWidth: '530px' }}
          />
          <AddYogaClass />
        </Box>

        <Box mt={2} p={2}>
          <TableContainer>
            <TblHead />
            <TableBody>
              {recordsAfterPagination().map((e, i) => (
                <TableRow key={i + 1}>
                  <TableCell>{e.name || e.title}</TableCell>
                  <TableCell>{e.room}</TableCell>
                  <TableCell>{coachName(e.coach)}</TableCell>
                  <TableCell>
                    {calculateTimeDifference(
                      cleanDate(e.start),
                      cleanDate(e.end)
                    )}
                  </TableCell>
                  <TableCell align='center'>
                    {(() => {
                      const status = classStatus(e)
                      return (
                        <Box
                          component={'span'}
                          p={1}
                          color={'#FFFFFF'}
                          borderRadius={3}
                          bgcolor={
                            status === 'active' ? 'lightgreen' : 'lightgray'
                          }
                          textTransform={'uppercase'}
                          fontSize={'x-small'}
                        >
                          {status}
                        </Box>
                      )
                    })()}
                  </TableCell>
                  <TableCell>
                    <Grid sx={{ placeItems: 'center' }} container>
                      <UpdateYogaClass yogaClass={e} />
                      <Tooltip title='Details'>
                        <Link
                          to={`/dashboard/admin/classes/${e.id}/reservations`}
                        >
                          <IconButton>
                            <VisibilityOutlinedIcon />
                          </IconButton>
                        </Link>
                      </Tooltip>
                    </Grid>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </TableContainer>
          <TblPagination />
        </Box>
      </Box>
    </DashboardLayout>
  )
}

export default AdminClasses
