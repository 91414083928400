import React from 'react'
import { Box, Grid } from '@mui/material'
import { People } from '@mui/icons-material'
import DashboardTile from '../../components/DashboardTile'
import SelfImprovementOutlinedIcon from '@mui/icons-material/SelfImprovementOutlined'
import BookOnlineOutlinedIcon from '@mui/icons-material/BookOnlineOutlined'
import { useStateValue } from '../../store/StateProvider'
import { isActive } from '../../lib/utils'
import { useGET } from '../../hooks/api/useGET.hook'

function AdminDashboard() {
  const { coaches, classes } = useStateValue()[0]
  const { data: users } = useGET('users', 'getAllUsers')
  const { data: reservations } = useGET(
    'reservations/active',
    'getActiveResercations'
  )

  return (
    <Box p={2}>
      <Grid container gap={3} justifyContent={'space-between'}>
        <DashboardTile
          title={'Users'}
          value={users?.filter((u) => u.role === 'user').length}
          icon={<People />}
        />
        <DashboardTile
          title={'Coaches'}
          value={coaches?.length}
          icon={<People />}
        />
        <DashboardTile
          title={'Active Classes'}
          value={classes.filter(isActive).length}
          icon={<SelfImprovementOutlinedIcon />}
        />
        <DashboardTile
          title={'Total Bookings'}
          value={reservations?.length}
          icon={<BookOnlineOutlinedIcon />}
        />
      </Grid>
    </Box>
  )
}

export default AdminDashboard
