import React, { createContext, useContext, useReducer } from 'react'
import { initialState } from './reducer'
export const StateContext = createContext()

export const StateProvider = ({ reducer, initialState, children }) => (
  <StateContext.Provider value={useReducer(reducer, initialState)}>
    {children}
  </StateContext.Provider>
)

/**
 * Hook to access Context Managed data
 * @returns {[typeof initialState,(payload:{
 * type: "SET_CLASSES" | "SET_CURRENT_CLASS" | "SET_CLASSS" | "SET_PRODUCTS" | "SET_USER" |
 * "SET_CLASSS_LOADED" | "SET_REPORTS" | "SET_SEARCHED" | "SET_COACHES" | "UPDATE_PRODUCT" |
 * "UPDATE_CLASS" | "UPDATE_COACH" | "UPDATE_USER" | "ADD_PRODUCT" | "ADD_CLASS" | "ADD_COACH" |
 * "DELETE_PRODUCT" | "DELETE_CLASS" | "DELETE_COACH" | "TOGGLE_CLASS_MODAL" | "TOGGLE_MODAL" | "LOGOUT" |
 * "TOGGLE_DRAWER" | "SET_DRAWER_WIDTH" | "SET_REFETCH_CLASSES" | "RESERVE_CLASS" , data:any})=>void]}
 */
export const useStateValue = () => useContext(StateContext)
