import ProductForm from '../forms/ProductForm'
import UpdateResourceButton from './UpdateResourceButton'

function UpdateProduct({ product }) {
  return (
    <UpdateResourceButton
      title={'Update Product'}
      FormComponent={ProductForm}
      componetProps={{ product }}
    />
  )
}

export default UpdateProduct
