import {
  Box,
  Grid,
  IconButton,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
} from '@mui/material'
import React, { useState } from 'react'
import Controls from '../../components/controls/Controls'
import { Search } from '@mui/icons-material'
import AddProduct from '../../components/modals/AddProduct'
import useTable from '../../hooks/useTable'
import { useStateValue } from '../../store/StateProvider'
import UpdateProduct from '../../components/modals/UpdateProduct'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import { Link } from 'react-router-dom'
import DashboardLayout from '../../layouts/DashboardLayout'

const headCells = [
  { id: 'name', label: 'Product Name' },
  { id: 'Category', label: 'Category' },
  { id: 'price', label: 'Price' },
  { id: 'active', label: 'Status' },
  { id: 'actions', label: 'Actions', disableSort: true },
]

function AdminProducts() {
  const { products } = useStateValue()[0]
  const [filter, setFilter] = useState({ fn: (items) => items })

  const handleSearch = (e) => {
    let query = e.target.value?.toLowerCase()
    setFilter({
      fn: (items) =>
        query
          ? items.filter(
              (item) =>
                item.name?.toLowerCase().includes(query) ||
                item.category?.toLowerCase().includes(query) ||
                item.price?.toString().toLowerCase().includes(query)
            )
          : items,
    })
  }

  const { TableContainer, TblHead, TblPagination, recordsAfterPagination } =
    useTable(products, headCells, filter)
  return (
    <DashboardLayout>
      <Box>
        <Box display={'flex'} gap={2} width={'100%'}>
          <Controls.Input
            label={'Search products'}
            changeFxn={handleSearch}
            InputProps={{ startAdornment: <Search /> }}
            style={{ flex: '1', maxWidth: '530px' }}
          />
          <AddProduct />
        </Box>

        <Box mt={2} p={2}>
          <TableContainer>
            <TblHead />
            <TableBody>
              {recordsAfterPagination().map((e, i) => (
                <TableRow key={i + 1}>
                  <TableCell>{e.name || e.title}</TableCell>
                  <TableCell>{e.category}</TableCell>
                  <TableCell>
                    {Number(e.price)?.toLocaleString('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    })}
                  </TableCell>
                  <TableCell align='center'>
                    {(() => {
                      const status = e.active
                      return (
                        <Box
                          component={'span'}
                          p={1}
                          color={'#FFFFFF'}
                          borderRadius={3}
                          bgcolor={status ? 'lightgreen' : 'lightgray'}
                          textTransform={'uppercase'}
                          fontSize={'x-small'}
                        >
                          {status ? 'Available' : 'Unavailable'}
                        </Box>
                      )
                    })()}
                  </TableCell>
                  <TableCell>
                    <Grid sx={{ placeItems: 'center' }} container>
                      <UpdateProduct product={e} />
                      <Tooltip title='Details'>
                        <Link to={`/dashboard/products/${e.id}`}>
                          <IconButton>
                            <VisibilityOutlinedIcon />
                          </IconButton>
                        </Link>
                      </Tooltip>
                    </Grid>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </TableContainer>
          <TblPagination />
        </Box>
      </Box>
    </DashboardLayout>
  )
}

export default AdminProducts
