import React from 'react'
import { Card, CardContent, CardHeader, Grid, Typography } from '@mui/material'

function DashboardTile({ title, icon, value }) {
  return (
    <Grid item xs={8} sm={6} md={4} lg={3}>
      <Card sx={{ borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}>
        <CardHeader
          sx={{
            backgroundColor: '#000000',
            color: '#FFFFFF',
          }}
          title={title}
        />
        <CardContent sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
          {icon}
          <Typography>{value}</Typography>
        </CardContent>
      </Card>
    </Grid>
  )
}

export default DashboardTile
