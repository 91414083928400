import { TextField } from '@mui/material'
import React, { useState } from 'react'
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'

function PasswordInput({
  name,
  value,
  error = null,
  changeFxn,
  label,
  ...others
}) {
  const [hide, setHide] = useState(1)

  return (
    <TextField
      variant='outlined'
      label={label}
      value={value}
      onChange={changeFxn}
      name={name}
      {...others}
      fullWidth={true}
      {...(error && { error: true, helperText: error })}
      InputProps={{
        endAdornment: hide ? (
          <VisibilityOutlinedIcon
            onClick={() => setHide(!hide)}
            sx={{
              cursor: 'pointer',
            }}
          />
        ) : (
          <VisibilityOffOutlinedIcon
            sx={{
              cursor: 'pointer',
            }}
            onClick={() => setHide(!hide)}
          />
        ),
      }}
      type={hide ? 'password' : 'text'}
    />
  )
}

export default PasswordInput
