import { Box, Grid, Typography } from '@mui/material'
import React from 'react'
import SelfImprovementOutlinedIcon from '@mui/icons-material/SelfImprovementOutlined'
import EventAvailableOutlinedIcon from '@mui/icons-material/EventAvailableOutlined'
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined'
import { format } from 'date-fns'
import { cleanDate } from '../lib/utils'
import { useStateValue } from '../store/StateProvider'
import { Person } from '@mui/icons-material'
function ClassDetail({ activeClass }) {
  const { coaches } = useStateValue()[0]
  const coachName = (id) => coaches.find((c) => c.id === id)?.name
  const classStatus =
    cleanDate(activeClass.end).getTime() <= Date.now() ? 'closed' : 'active'
  return (
    <Grid container my={2}>
      <Grid item xs={12} md={6} display='flex' flexDirection='column' gap={1}>
        <Box display={'flex'} gap={2}>
          <Box display={'flex'} color={'#555'} fontWeight={'bold'} gap={0.5}>
            <SelfImprovementOutlinedIcon />
            <span>Class</span>
          </Box>
          <Typography>{activeClass.title}</Typography>
        </Box>
        <Box display={'flex'} gap={2} maxHeight={'fit-content'}>
          <Box display={'flex'} color={'#555'} fontWeight={'bold'} gap={0.5}>
            <EventAvailableOutlinedIcon />
            <span>Status</span>
          </Box>
          <Typography sx={{ textTransform: 'capitalize' }}>
            {classStatus}
          </Typography>
        </Box>
        <Box display={'flex'} gap={2}>
          <Box display={'flex'} color={'#555'} fontWeight={'bold'} gap={0.5}>
            <Person />
            <span>Coach</span>
          </Box>
          <Typography>{coachName(activeClass.coach)}</Typography>
        </Box>
      </Grid>
      <Grid item xs={12} md={6} display='flex' flexDirection='column' gap={1}>
        <Box display={'flex'} gap={2} maxHeight={'fit-content'}>
          <Box display={'flex'} color={'#555'} fontWeight={'bold'} gap={0.5}>
            <DateRangeOutlinedIcon />
            <span>Start</span>
          </Box>
          <Typography>
            {' '}
            {format(cleanDate(activeClass.start), 'dd-MMM-yyyy hh:mm a')}
          </Typography>
        </Box>
        <Box display={'flex'} gap={2} maxHeight={'fit-content'}>
          <Box display={'flex'} color={'#555'} fontWeight={'bold'} gap={0.5}>
            <DateRangeOutlinedIcon />
            <span>End</span>
          </Box>
          <Typography>
            {' '}
            {format(cleanDate(activeClass.end), 'dd-MMM-yyyy hh:mm a')}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  )
}

export default ClassDetail
