import React from 'react'
import {
  AppBar,
  Toolbar,
  IconButton,
  Badge,
  MenuItem,
  Menu,
  Box,
  Divider,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material'
import { ShoppingCart } from '@mui/icons-material'
import logo from '../../assets/contornomock1.png'
import logo2 from '../../assets/contornomock2.png'
import useStyles from './styles'
import { Link, useNavigate } from 'react-router-dom'
import LoginForm from '../modals/LoginForm'
import { useStateValue } from '../../store/StateProvider'
import AccountCircle from '@mui/icons-material/AccountCircle'
import PowerSettingsNewOutlinedIcon from '@mui/icons-material/PowerSettingsNewOutlined'
import { logOut } from '../../firebase/auth'
import { toast } from 'react-toastify'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import MenuIcon from '@mui/icons-material/Menu'

const Navbar = () => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [{ user: auth, drawerWidth }, dispatch] = useStateValue()

  const navigate = useNavigate()

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (fn) => {
    setAnchorEl(null)
  }

  const handleLogout = () => {
    handleClose()
    logOut().then(() => {
      dispatch({ type: 'LOGOUT' })
      toast.success('Logout successfully!')
    })
  }

  const classes = useStyles()
  return (
    <AppBar
      position='sticky'
      className={classes.appBar}
      elevation={1}
      color='inherit'
      sx={{
        width: { sm: `calc(100vw - ${drawerWidth}px)` },
        ml: { sm: `${drawerWidth}px` },
      }}
    >
      <Toolbar>
        <IconButton
          color='inherit'
          aria-label='open drawer'
          edge='start'
          onClick={() => dispatch({ type: 'TOGGLE_DRAWER' })}
          sx={{ mr: 2, display: { sm: 'none' } }}
        >
          <MenuIcon />
        </IconButton>
        <div className='flex w-full items-center justify-evenly'>
          <Link to='/'>
            <div className='flex'>
              <img
                src={logo2}
                alt='Commerce.js'
                height='35px'
                className={classes.image}
              />
              <img
                src={logo}
                alt='Commerce.js'
                height='35px'
                className={classes.image}
              />
            </div>
          </Link>
          <Link to='/calendar2' className='hover:underline color-inherit'>
            <h1> RESERVA </h1>
          </Link>
          <Link to='/' className='hover:underline color-inherit'>
            <h1>MENU 2</h1>
          </Link>
          <Link to='/' className='hover:underline color-inherit'>
            <h1>MENU 3</h1>
          </Link>
          <div className='flex'>
            <IconButton aria-label='Show cart items' color='inherit'>
              <Badge badgeContent={auth?.cart?.length} color='secondary'>
                <Link to='/cart'>
                  <ShoppingCart />
                </Link>
              </Badge>
            </IconButton>
          </div>
          <div>
            <Box display={'flex'} flex={1} justifyContent={'flex-end'}>
              {auth ? (
                <div>
                  <IconButton
                    size='large'
                    aria-label='account of current user'
                    aria-controls='menu-appbar'
                    aria-haspopup='true'
                    onClick={handleMenu}
                    color='inherit'
                  >
                    <AccountCircle fontSize='large' />
                  </IconButton>
                  <Typography
                    component={'span'}
                    display={'inline'}
                    onClick={handleMenu}
                    sx={{ cursor: 'pointer' }}
                  >
                    {auth?.name?.split(' ')[0]}
                  </Typography>
                  <Menu
                    id='menu-appbar'
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    <MenuItem
                      onClick={() => {
                        handleClose()
                        navigate('/dashboard/profile')
                      }}
                    >
                      Profile
                    </MenuItem>
                    <Divider />

                    <MenuItem
                      onClick={() => {
                        handleClose()
                        navigate('/dashboard')
                      }}
                    >
                      <ListItemIcon>
                        <SettingsOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText>Dashboard</ListItemText>
                    </MenuItem>

                    <MenuItem onClick={handleLogout}>
                      <ListItemIcon>
                        <PowerSettingsNewOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText>Logout</ListItemText>
                    </MenuItem>
                  </Menu>
                </div>
              ) : (
                <LoginForm />
              )}
            </Box>
          </div>
        </div>
      </Toolbar>
    </AppBar>
  )
}

export default Navbar
