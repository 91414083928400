import { QueryClient } from 'react-query'

const queryClientSettings = {
  defaultOptions: {
    queries: {
      retry: 1,
      refetchOnMount: 'true',
      refetchOnWindowFocus: 'false',
      refetchOnReconnect: 'always',
      cacheTime: 3.6e6,
      refetchInterval: 3.6e6, //1 hour
      refetchIntervalInBackground: true,
      suspense: false,
      staleTime: 3.6e6,
    },
    mutations: {
      retry: 1,
    },
  },
}

const queryClient = new QueryClient(queryClientSettings)

export default queryClient
