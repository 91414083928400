import React from 'react'
import { Container, Typography, Button } from '@mui/material'
import useStyles from './styles'
import { useStateValue } from '../../store/StateProvider'
import { useNavigate } from 'react-router-dom'
import CartItemsList from './CartItem/CartItemsList'
import { usePATCH } from '../../hooks/api/usePATCH.hook'

const Cart = () => {
  const classes = useStyles()
  const [{ user }, dispatch] = useStateValue()

  const { mutate: saveCart, isLoading } = usePATCH(
    'users/me',
    (data) => {
      dispatch({ type: 'UPDATE_USER', data })
    },
    false
  )

  const navigate = useNavigate()

  const EmptyCart = () => (
    <Typography variant='subtitle1' fontStyle={'italic'}>
      You have no items in your shopping cart, start adding some!
    </Typography>
  )

  const FilledCart = () => (
    <>
      <CartItemsList loading={isLoading} saveCart={saveCart} />
      <div className={classes.cardDetails}>
        {/* <div className='flex gap-2'> */}
        <Button
          className={classes.emptyButton}
          size='large'
          type='button'
          variant='contained'
          color='secondary'
          disabled={isLoading}
          onClick={() => saveCart({ cart: [] })}
        >
          Empty Cart
        </Button>
        <Button
          className={classes.checkoutButton}
          size='large'
          type='button'
          variant='contained'
          color='primary'
          onClick={() => navigate('/checkout')}
        >
          Checkout
        </Button>
        {/* </div> */}
      </div>
    </>
  )

  return (
    <Container>
      <div className={classes.toolbar} />
      <Typography
        className={classes.title}
        variant='h3'
        fontSize={'28px'}
        gutterBottom
      >
        Your Shopping Cart
      </Typography>
      {!user?.cart.length ? <EmptyCart /> : <FilledCart />}
    </Container>
  )
}

export default Cart
