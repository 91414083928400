import React, { useState } from 'react'
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js'
import { Form } from '../../hooks/useForm'
import Popup from '../Popup'
import { Button } from '@mui/base'
import axiosInstance from '../../lib/axiosInstance'
import Controls from '../controls/Controls'
import { Box, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

function PayWithStripe({ callBackUrl, getIntentUrl, redirectUrl, amount }) {
  const stripe = useStripe()
  const elements = useElements()
  const [open, setOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()

  const handlePayment = async (event) => {
    event.preventDefault()
    setIsLoading(true)
    try {
      const response = await axiosInstance.get(getIntentUrl)
      if (response.status >= 200 && response.status < 400) {
        const data = response.data?.data
        console.log('>>>', data)
        const confirmPayment = await stripe.confirmCardPayment(
          data.clientSecret,
          {
            payment_method: {
              card: elements.getElement(CardNumberElement),
            },
          }
        )
        console.log('>>> Intent', confirmPayment.paymentIntent)
        const callbackResponse = await axiosInstance.get(callBackUrl + data.id)
        if (callbackResponse.status === 200) {
          toast.success(callbackResponse.data.message)
          setOpen(false)
          navigate(redirectUrl)
        } else {
          toast.error(callbackResponse.data.message)
        }
      } else {
        toast.error('Error Fetching Intent')
        console.log(response.data)
      }
    } catch (error) {
      toast.error(error.response?.data?.message || error.message)
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <Popup open={open} setOpen={setOpen} title={'Fill Your Card Details'}>
        <Form onSubmit={handlePayment}>
          <Typography component={'div'} display={'flex'} gap={1}>
            Payment Amount :{' '}
            <Typography
              sx={{
                fontFamily: 'sans-serif',
                fontSize: '16px',
                lineHeight: '1.5',
                textAlign: 'right',
                fontWeight: 'bold',
              }}
            >
              {Number(amount)?.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
              })}
            </Typography>
          </Typography>
          <Box
            border={'solid 1px black'}
            p={2}
            width={'100%'}
            fontSize={'x-large'}
            borderRadius={3}
            mb={1.5}
          >
            <CardNumberElement
              options={{
                style: {
                  base: {
                    fontSize: '18px',
                    color: '#424770',
                    '::placeholder': {
                      color: '#aab7c4',
                    },
                  },
                  invalid: {
                    color: '#9e2146',
                  },
                },
              }}
            />
          </Box>
          <Box
            border={'solid 1px black'}
            p={2}
            width={'100%'}
            fontSize={'x-large'}
            borderRadius={3}
            mb={1.5}
          >
            <CardExpiryElement
              options={{
                style: {
                  base: {
                    fontSize: '18px',
                    color: '#424770',
                    '::placeholder': {
                      color: '#aab7c4',
                    },
                  },
                  invalid: {
                    color: '#9e2146',
                  },
                },
              }}
            />
          </Box>
          <Box
            border={'solid 1px black'}
            p={2}
            width={'100%'}
            fontSize={'x-large'}
            borderRadius={3}
            mb={1.5}
          >
            <CardCvcElement
              options={{
                style: {
                  base: {
                    fontSize: '18px',
                    color: '#424770',
                    '::placeholder': {
                      color: '#aab7c4',
                    },
                  },
                  invalid: {
                    color: '#9e2146',
                  },
                },
              }}
            />
          </Box>
          <Controls.Button
            variant='contained'
            size='small'
            type='submit'
            text={'Confirm Payment'}
            disabled={isLoading}
          />
        </Form>
      </Popup>
      <Button
        variant='contained'
        size='small'
        onClick={() => setOpen(true)}
        sx={{ maxWidth: '130px' }}
      >
        Checkout
      </Button>
    </>
  )
}

export default PayWithStripe
