import React, { useState } from "react";
import { Form } from "../hooks/useForm";
import { WEBSITE_PASSWORD } from "../lib/utils";
import contornologo from "../assets/Layer_1.png";
import locksvg from "../assets/lock.svg";

function PasswordPage({ setPassword: setCorrectPassword }) {
  const [error, setError] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = () => {
    if (password !== WEBSITE_PASSWORD) setError("Password Incorrect");
    else {
      setError("");
      setCorrectPassword(password);
    }
  };
  return (
    <div className="w-full h-[100dvh] flex flex-col gap-[2rem] items-center password-page ">
      <div className="md:mt-[10vh] mt-[30dvh]">
        <img
          src={contornologo}
          alt="Contorno Pilates"
          className="w-[19.125rem] h-[7.5rem] md:w-[26rem] md:h-[10.5rem] aspect-square"
        />
      </div>
      <div className="flex flex-col gap-[2rem]">
        <p className="flex custom-p justify-center gap-[4rem]">May 2024</p>
        <div className="w-[15.8rem] h-[2.2rem] md:w-[28rem] md:h-[3.5rem]  border-[#B05B72] rounded-full border-[2px] flex justify-center items-center">
          <Form onSubmit={handleSubmit}>
            <div className="flex justify-center items-center w-full">
              <img
                src={locksvg}
                alt=""
                className="w-[15px] h-[22px] md:w-[26px] md:h-[28px]"
              />
              <input
                type="password"
                placeholder="Password"
                className="h-[23px] w-[150px] password-input md:h-[55px] align-center"
                name={"password"}
                label={"Password"}
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
            </div>
          </Form>
        </div>
      </div>
      <p className="custom-p2">Stay Tuned</p>
    </div>
  );
}

export default PasswordPage;
