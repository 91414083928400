import React from 'react'
import useStyles from './styles'
import Hero from './../Hero/hero'
import { Grid, Typography } from '@mui/material'
import Product from './Product/Product'
import { useStateValue } from '../../store/StateProvider'

const Products = () => {
  const { products } = useStateValue()[0]
  const classes = useStyles()
  return (
    <main className={classes.content}>
      <Hero />
      <div className={classes.toolbar} />
      <Typography
        variant='title'
        fontSize={{ xs: '18px', md: '32px' }}
        textAlign={'center'}
        component={'p'}
        width={'100%'}
      >
        Our Products
      </Typography>
      <Grid container justify='center' spacing={2}>
        {products.map((product) => (
          <Grid item key={product.id} xs={12} sm={6} md={4} lg={3}>
            <Product product={product} />
          </Grid>
        ))}
      </Grid>
    </main>
  )
}

export default Products
