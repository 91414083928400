import { Box, Typography } from '@mui/material'
import React from 'react'

function UserDashboard() {
  return (
    <Box p={2}>
      <Typography>User Dashboard</Typography>
    </Box>
  )
}

export default UserDashboard
