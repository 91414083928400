import DashboardLayout from '../../layouts/DashboardLayout'
import { Box, TableBody, TableCell, TableRow } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Controls from '../../components/controls/Controls'
import { Search } from '@mui/icons-material'
import useTable from '../../hooks/useTable'
import { useStateValue } from '../../store/StateProvider'
import { format } from 'date-fns'
import { cleanDate, toCurrency } from '../../lib/utils'
import Loading from '../../components/loading/Loading'
import { useGET } from '../../hooks/api/useGET.hook'

const headCells = [
  { id: 'products', label: 'No of Products' },
  { id: 'amount', label: 'Amount' },
  { id: 'status', label: 'Order Status' },
  { id: 'date', label: 'Date Ordered' },
]

function MyOrders() {
  const { user } = useStateValue()[0]
  const [orders, setOrders] = useState([])
  const { data, isLoading } = useGET(
    `users/me/orders`,
    `getMyOrders${user?.cart?.map((c) => c.product + c.quantity).join('')}`
  )

  const [filter, setFilter] = useState({ fn: (items) => items })
  const handleSearch = (e) => {
    let query = e.target.value?.toLowerCase()
    setFilter({
      fn: (items) =>
        query
          ? items.filter(
              (item) =>
                item.user?.name?.toLowerCase().includes(query) ||
                item.user?.email?.toLowerCase().includes(query)
            )
          : items,
    })
  }
  const { TableContainer, TblHead, TblPagination, recordsAfterPagination } =
    useTable(orders, headCells, filter)
  useEffect(() => {
    setOrders(data || [])
  }, [data])

  return (
    <DashboardLayout>
      <Box p={2} gap={1}>
        {isLoading && <Loading />}
        <Box display={'flex'} gap={2} width={'100%'} mt={1}>
          <Controls.Input
            label={'Search orders'}
            changeFxn={handleSearch}
            InputProps={{ startAdornment: <Search /> }}
            style={{ flex: '1', maxWidth: '530px' }}
            size='small'
          />
        </Box>
        <Box p={1}>
          <TableContainer>
            <TblHead />
            <TableBody>
              {recordsAfterPagination().map((e, i) => (
                <TableRow key={i + 1}>
                  <TableCell>{e.products?.length}</TableCell>
                  <TableCell>{toCurrency(e.amount)}</TableCell>
                  <TableCell align='center'>
                    {(() => {
                      const status = e.status
                      return (
                        <Box
                          component={'span'}
                          p={1}
                          color={'#FFFFFF'}
                          borderRadius={3}
                          bgcolor={
                            status === 'active' ? 'lightgreen' : 'lightgray'
                          }
                          textTransform={'uppercase'}
                          fontSize={'x-small'}
                        >
                          {status}
                        </Box>
                      )
                    })()}
                  </TableCell>
                  <TableCell>
                    {format(cleanDate(e.createdAt), 'dd-MMM-yyyy hh:mm a')}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </TableContainer>
          <TblPagination />
        </Box>
      </Box>
    </DashboardLayout>
  )
}

export default MyOrders
