import React from 'react'
import { useStateValue } from '../store/StateProvider'
import DashboardLayout from '../layouts/DashboardLayout'
import AdminDashboard from './admin/AdminDashboard'
import CoachDashboard from './coach/CoachDashboard'
import UserDashboard from './user/UserDashboard'

function Dashboard() {
  const { user } = useStateValue()[0]
  return (
    <DashboardLayout>
      {user.role === 'admin' ? (
        <AdminDashboard />
      ) : user.role === 'coach' ? (
        <CoachDashboard />
      ) : (
        <UserDashboard />
      )}
    </DashboardLayout>
  )
}

export default Dashboard
