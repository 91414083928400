import React from 'react'
import { Box } from '@mui/material'
import { Form, useForm } from '../../hooks/useForm'
import Controls from '../controls/Controls'
import { useStateValue } from '../../store/StateProvider'
import dayjs from 'dayjs'
import usePOST from '../../hooks/api/usePOST.hook'
import { usePATCH } from '../../hooks/api/usePATCH.hook'

const newForm = {
  title: '',
  coach: '',
  noOfSlots: 1,
  start: dayjs(new Date()).add(10, 'minutes'),
  end: dayjs(new Date()).add(1, 'hour'),
  room: '',
  price: 1,
}

function YogaClassForm({
  yogaClass = undefined,
  errorCallback = () => {},
  successCallback = () => {},
}) {
  const [{ coaches }, dispatch] = useStateValue()
  const validate = (fields = values) => {
    const temp = { ...errors }
    ;['title', 'room', 'start', 'end', 'coach', 'noOfSlots'].forEach(
      (required) => {
        if (required in fields)
          temp[required] = fields[required] ? '' : `${required} is required`
      }
    )

    // validate end date
    if (fields.start && fields.end) {
      if (dayjs(fields.start).isBefore(dayjs(new Date())))
        temp.start = 'start date can not be past'
      if (dayjs(fields.end).isBefore(dayjs(fields.start)))
        temp.end = 'end date must be later than start'
    }

    setErrors({ ...temp })
    if (fields === values) return Object.values(temp).every((x) => x === '')
  }
  const [values, resetForm, errors, setErrors, changeFunction] = useForm(
    yogaClass
      ? {
          ...yogaClass,
          start: dayjs(yogaClass.start),
          end: dayjs(yogaClass.end),
        }
      : newForm,
    validate,
    true
  )
  const {
    mutate: create,
    isLoading: createLoading,
    isError: isCreateError,
    error: createError,
  } = usePOST('classes', (data) => {
    if (!isCreateError) {
      successCallback()
      resetForm()
      dispatch({ type: 'ADD_CLASS', data })
    } else {
      errorCallback(createError)
    }
  })

  const {
    mutate: update,
    isLoading: updateLoading,
    isError: isUpdateError,
    error: updateError,
  } = usePATCH(`classes/${yogaClass?.id}`, (data) => {
    if (!isUpdateError) {
      successCallback()
      resetForm()
      dispatch({ type: 'UPDATE_CLASS', data })
    } else {
      errorCallback(updateError)
    }
  })

  const handleSubmit = () => {
    if (!validate(values)) return
    const data = {
      ...values,
      start: values.start.toDate(),
      end: values.end.toDate(),
      price: 1, // contingency
    }
    if (yogaClass) {
      update(data)
    } else {
      create(data)
    }
  }
  return (
    <Form onSubmit={handleSubmit}>
      <Controls.Input
        label={'Title'}
        name={'title'}
        value={values.title}
        changeFxn={changeFunction}
        error={errors.title}
      />
      <Controls.Select
        label={'Room Type'}
        name={'room'}
        value={values.room}
        options={['HOT ROOM', 'CONVENTIONAL ROOM', 'COLD ROOM']}
        changeFxn={changeFunction}
        error={errors.room}
      />
      <Controls.Input
        label={'No of Beds'}
        name={'noOfSlots'}
        type={'number'}
        value={values.noOfSlots}
        changeFxn={changeFunction}
        error={errors.noOfSlots}
      />
      <Controls.Select
        label={'Coach'}
        name={'coach'}
        value={values.coach}
        changeFxn={changeFunction}
        error={errors.coach}
        options={coaches}
      />
      <Controls.DateTimePicker
        name={'start'}
        label={'Start Time'}
        error={errors.start}
        value={values.start}
        changeFunction={changeFunction}
      />
      <Controls.DateTimePicker
        name={'end'}
        label={'End Time'}
        error={errors.end}
        value={values.end}
        changeFunction={changeFunction}
      />
      <Box display='flex' gap={2} disabled={createLoading | updateLoading}>
        <Controls.Button
          variant={''}
          text={
            yogaClass
              ? updateLoading
                ? 'updating..'
                : 'update'
              : createLoading
              ? 'creating..'
              : 'create'
          }
          type='submit'
        />
        {/* <Controls.Button /> */}
      </Box>
    </Form>
  )
}

export default YogaClassForm
