import { Box, Grid } from '@mui/material'
import React from 'react'
import DashboardTile from '../../components/DashboardTile'
import { People } from '@mui/icons-material'
import SelfImprovementOutlinedIcon from '@mui/icons-material/SelfImprovementOutlined'
import { useStateValue } from '../../store/StateProvider'
import { isActive } from '../../lib/utils'

function CoachDashboard() {
  const { classes, user: coach } = useStateValue()[0]

  return (
    <Box p={2}>
      <Grid container gap={3} justifyContent={'space-between'}>
        <DashboardTile
          title={'Upcoming Classes'}
          value={
            classes.filter((c) => c.coach === coach.id && isActive(c)).length
          }
          icon={<People />}
        />
        <DashboardTile
          title={'Total Assigned Classes'}
          value={classes.filter((c) => c.coach === coach.id).length}
          icon={<SelfImprovementOutlinedIcon />}
        />
      </Grid>
    </Box>
  )
}

export default CoachDashboard
