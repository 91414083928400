import React from 'react'
import { Box } from '@mui/material'
import { Form, useForm } from '../../hooks/useForm'
import Controls from '../controls/Controls'
import { useStateValue } from '../../store/StateProvider'
import usePOST from '../../hooks/api/usePOST.hook'
import { usePATCH } from '../../hooks/api/usePATCH.hook'

const newForm = {
  email: '',
  name: '',
  password: '',
  role: 'coach',
}

function UserForm({
  user = undefined,
  errorCallback = () => {},
  successCallback = () => {},
}) {
  const dispatch = useStateValue()[1]
  const validate = (fields = values) => {
    const temp = { ...errors }
    ;['email', 'name', 'password', 'role'].forEach((required) => {
      if (required in fields)
        temp[required] = fields[required] ? '' : `${required} is required`
    })

    setErrors({ ...temp })
    if (fields === values) return Object.values(temp).every((x) => x === '')
  }
  const [values, resetForm, errors, setErrors, changeFunction] = useForm(
    user ? user : newForm,
    validate,
    true
  )
  const {
    mutate: create,
    isLoading: createLoading,
    isError: isCreateError,
    error: createError,
  } = usePOST('users', (data) => {
    if (!isCreateError) {
      successCallback()
      resetForm()
      dispatch({ type: 'ADD_COACH', data })
    } else {
      errorCallback(createError)
    }
  })

  const {
    mutate: update,
    isLoading: updateLoading,
    isError: isUpdateError,
    error: updateError,
  } = usePATCH(`users/${user?.id}`, (data) => {
    if (!isUpdateError) {
      successCallback()
      resetForm()
      dispatch({ type: 'UPDATE_COACH', data })
    } else {
      errorCallback(updateError)
    }
  })

  const handleSubmit = () => {
    if (!validate(values)) return
    const data = {
      ...values,
    }
    if (user) {
      update(data)
    } else {
      create(data)
    }
  }
  return (
    <Form onSubmit={handleSubmit}>
      <Controls.Input
        label={'Email Address'}
        name={'email'}
        value={values.email}
        changeFxn={changeFunction}
        error={errors.email}
      />
      <Controls.Input
        label={'Name'}
        name={'name'}
        value={values.name}
        changeFxn={changeFunction}
        error={errors.name}
      />
      <Controls.Input
        name={'password'}
        type='password'
        label={'Password'}
        error={errors.password}
        value={values.password}
        changeFxn={changeFunction}
      />
      <Box display='flex' gap={2}>
        <Controls.Button
          disabled={createLoading | updateLoading}
          variant={''}
          text={
            user
              ? updateLoading
                ? 'updating..'
                : 'update'
              : createLoading
              ? 'creating..'
              : 'create'
          }
          type='submit'
        />
        {/* <Controls.Button /> */}
      </Box>
    </Form>
  )
}

export default UserForm
