import axios from 'axios'
import { auth } from '../firebase/_config'

const axiosInstance = axios.create({
  baseURL:
    (process.env.REACT_APP_ENV === 'development'
      ? process.env.REACT_APP_API_BASE_URL_LOCAL
      : process.env.REACT_APP_API_BASE_URL) ||
    'https://api-ae33xwsk5a-uc.a.run.app',
  timeout: 50000,
})

/**
 * Add Token Before backend requests
 */
axiosInstance.interceptors.request.use(
  async function (config) {
    const token = await auth.currentUser?.getIdToken(true)
    // console.log('>>>>', token)
    if (token) config.headers['authorization'] = `Bearer ${token}`
    return config
  },
  (err) => Promise.reject(err)
)

export default axiosInstance
