import React, { useEffect, useState } from 'react'
import { Box, Button, Grid, Typography } from '@mui/material'
import { Link, useParams } from 'react-router-dom'
import { useStateValue } from '../store/StateProvider'
import { format } from 'date-fns'
import usePOST from '../hooks/api/usePOST.hook'

import { toast } from 'react-toastify'
import { areEqualDates, cleanDate } from '../lib/utils'
import ReservationDetails from '../components/ReservationDetails'
import Back from '../components/Back'
import Email from '../lib/email'

function Reserve() {
  const { id } = useParams()
  const [activeClass, setActiveClass] = useState()
  const [available, setAvailable] = useState()
  const [{ classes, coaches, user, userDetails }, dispatch] = useStateValue()
  const [reservation, setReservation] = useState(null)
  const [bed, setBed] = useState(-1)

  const coachName = (id) => coaches.find((c) => c.id === id)?.name

  useEffect(() => {
    const c = classes.find((c) => c.id === id) || {}
    setActiveClass({ ...c, start: cleanDate(c.start), end: cleanDate(c.end) })
    setAvailable(c && cleanDate(c.start).getTime() > Date.now())
  }, [classes, id])

  useEffect(() => {
    setReservation(
      userDetails?.reservations?.find(
        (r) =>
          r.yClass?.id === activeClass?.id &&
          areEqualDates(r.yClass?.start, activeClass?.start)
      )
    )
  }, [userDetails, activeClass])

  const { mutate, isError, isLoading } = usePOST(
    `classes/${id}/reserve`,
    (data) => {
      if (!isError) {
        //  setOpen(false)
        //  resetForm()
        dispatch({ type: 'RESERVE_CLASS', data })
        dispatch({
          type: 'UPDATE_CLASS',
          data: {
            ...activeClass,
            reservations: [...activeClass.reservations, bed],
          },
        })
        toast.success('reservation successful')
        Email.sendReservationNotice(user, data)
          .then(console.log)
          .catch(console.error)
      }
    }
  )

  const handleReserve = async () => {
    if (!user) return toast.info('You are not logged in, login to proceed')
    if (bed < 1) return toast.info('Please choose a bed')
    const proceed = prompt(
      'You are about to reserve this class\n\nEnter yes to proceed',
      'no'
    ).toLocaleLowerCase()
    if (proceed !== 'yes') return
    mutate({ bed })
  }
  return (
    <Box
      width={'98dvw'}
      minHeight={'calc(100dvh - 80px)'}
      display={'flex'}
      justifyContent={'flex-start'}
      alignItems={'center'}
      flexDirection={'column'}
    >
      <Back />
      {reservation ? (
        <ReservationDetails reservation={reservation} />
      ) : (
        <Box
          display={'flex'}
          flexDirection={'column'}
          alignItems={'center'}
          width={'80%'}
          height={'80%'}
          minHeight={'600px'}
          maxWidth={'800px'}
          bgcolor={'#D9D9D9'}
          p={2}
        >
          <Typography variant='title' fontWeight={'bold'} fontSize={'large'}>
            {activeClass?.title} - {coachName(activeClass?.coach)}
          </Typography>
          <Typography>
            Time - {activeClass && format(activeClass?.start, 'MM/dd hh:mm a')}{' '}
            : {activeClass && format(activeClass?.end, 'MM/dd hh:mm a')}
          </Typography>
          <Typography
            fontWeight='bold'
            mt={2}
            color={available ? 'darkgreen' : 'red'}
          >
            {available ? 'Opened' : 'Closed'}
          </Typography>
          <Typography mt={3}>Choose Bed Space</Typography>
          <Grid
            my={2}
            justifyContent={'center'}
            p={2}
            container
            gap={1}
            bgcolor={'#FFFFFF'}
            width={'min(400px, 80%)'}
            minHeight={'200px'}
          >
            {Array(Number(activeClass?.noOfSlots) || 1)
              .fill(null)
              .map((_, i) => (
                <Grid
                  item
                  md={2}
                  xs={3}
                  key={i}
                  width={'50px'}
                  height={'50px'}
                  display={'flex'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  borderRadius={3}
                  onClick={() => {
                    !activeClass?.reservations?.includes(i + 1) && setBed(i + 1)
                  }}
                  sx={{
                    cursor: 'pointer',
                    ':hover': { transform: 'translateY(-3px)' },
                    bgcolor: activeClass?.reservations?.includes(i + 1)
                      ? 'lightgray'
                      : 'lightgreen',
                    border: i + 1 === bed ? 'solid 2px darkgreen' : 'none',
                  }}
                  bgcolor={'primary.light'}
                >
                  {i + 1}
                </Grid>
              ))}
          </Grid>
          <Box
            width={'min(400px, 80%)'}
            display='flex'
            my={1}
            justifyContent='space-between'
          >
            <Typography color={user?.passes ? 'primary' : 'red'}>
              Available Passes: {user?.passes || 0}
            </Typography>

            {!user?.passes && (
              <Link to='/buy-pass'>
                <Typography
                  sx={{
                    fontSize: 'small',
                    cursor: 'pointer',
                    borderRadius: 3,
                    // p: 1,
                  }}
                  fontWeight={'bold'}
                  fontStyle={'italic'}
                >
                  buy passes
                </Typography>
              </Link>
            )}
          </Box>
          <Button
            variant='contained'
            disabled={isLoading || !user?.passes || !available}
            onClick={handleReserve}
          >
            {isLoading ? 'reserving...' : 'Reserve'}
          </Button>
        </Box>
      )}
    </Box>
  )
}

export default Reserve
