import { Box, TableBody, TableCell, TableRow } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Controls from '../../components/controls/Controls'
import { Search } from '@mui/icons-material'
import useTable from '../../hooks/useTable'
import { useStateValue } from '../../store/StateProvider'
import { format } from 'date-fns'
import { cleanDate } from '../../lib/utils'
import { useParams } from 'react-router-dom'
import DashboardLayout from '../../layouts/DashboardLayout'
import Loading from '../../components/loading/Loading'
import { useGET } from '../../hooks/api/useGET.hook'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import { DownloadTableExcel } from 'react-export-table-to-excel'
import ClassDetail from '../../components/ClassDetail'

const headCells = [
  { id: 'user.name', label: 'Name' },
  { id: 'user.email', label: 'Email' },
  { id: 'bed', label: 'Reserved Seat' },
  { id: 'date', label: 'Date Reserved' },
  { id: 'status', label: 'Status' },
]

function ClassReservations() {
  const { classes } = useStateValue()[0]
  const [reservations, setReservations] = useState([])
  const [activeClass, setActiveClass] = useState({})
  const { id } = useParams()
  const { data, isLoading } = useGET(
    `classes/${id}/reservations`,
    `getAll${id}Reservations`
  )

  const [filter, setFilter] = useState({ fn: (items) => items })
  const handleSearch = (e) => {
    let query = e.target.value?.toLowerCase()
    setFilter({
      fn: (items) =>
        query
          ? items.filter(
              (item) =>
                item.user.name?.toLowerCase().includes(query) ||
                item.user?.email?.toLowerCase().includes(query)
            )
          : items,
    })
  }
  const {
    TableContainer,
    TblHead,
    TblPagination,
    recordsAfterPagination,
    tableRef,
  } = useTable(reservations, headCells, filter)
  useEffect(() => {
    console.log('>>>', data)
    setReservations(data || [])
  }, [data])
  useEffect(() => {
    if (id) setActiveClass(classes.find((c) => c.id === id) || {})
  }, [id, classes])
  return (
    <DashboardLayout>
      <Box p={2} gap={1}>
        {isLoading && <Loading />}
        <Box display={'flex'} gap={2} width={'100%'}>
          <ClassDetail activeClass={activeClass} />
        </Box>
        <Box display={'flex'} gap={2} width={'100%'} mt={1}>
          <Controls.Input
            label={'Search Reservations'}
            changeFxn={handleSearch}
            InputProps={{ startAdornment: <Search /> }}
            style={{ flex: '1', maxWidth: '530px' }}
            size='small'
          />
          <DownloadTableExcel
            filename={`${activeClass.title}_reservations_${format(
              new Date(),
              'dd-MM-yy'
            )}`}
            sheet={activeClass.title}
            currentTableRef={tableRef.current}
          >
            <Controls.Button
              text={'Excel'}
              variant={'contained'}
              size='small'
              endArdonement={<FileDownloadOutlinedIcon />}
            />
          </DownloadTableExcel>
        </Box>
        <Box p={1}>
          <TableContainer>
            <TblHead />
            <TableBody>
              {recordsAfterPagination().map((e, i) => (
                <TableRow key={i + 1}>
                  <TableCell>{e.user?.name}</TableCell>
                  <TableCell>{e.user?.email}</TableCell>
                  <TableCell>{e.bed}</TableCell>
                  <TableCell>
                    {format(cleanDate(e.createdAt), 'dd-MMM-yyyy hh:mm a')}
                  </TableCell>
                  <TableCell align='center'>
                    {(() => {
                      const status = e.status
                      return (
                        <Box
                          component={'span'}
                          p={1}
                          color={'#FFFFFF'}
                          borderRadius={3}
                          bgcolor={
                            status === 'active' ? 'lightgreen' : 'lightgray'
                          }
                          textTransform={'uppercase'}
                          fontSize={'x-small'}
                        >
                          {status}
                        </Box>
                      )
                    })()}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </TableContainer>
          <TblPagination />
        </Box>
      </Box>
    </DashboardLayout>
  )
}

export default ClassReservations
