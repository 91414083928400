/**
 * Make sure a date variable is a date
 * @param {any} date
 * @returns {Date}
 */

export const cleanDate = (date) => {
  if (!date) return new Date();
  if (!(date instanceof Date)) {
    if (date?.seconds || date?._seconds) {
      date = new Date(
        (date.seconds || date._seconds) * 1000 +
          (date.nanoseconds || date._nanoseconds) / 1000000
      );
    } else date = new Date(date);
    if (date === "Invalid Date") {
      date = new Date();
    }
  }
  return date;
};

/**
 * Calculates the time difference between two dates and returns the duration in a human-readable format.
 *
 * @param {Date} startDate The start date
 * @param {Date} endDate The end date
 * @returns {string} The time difference in hours and minutes, or "0 minutes" if both hours and minutes are zero
 */
export function calculateTimeDifference(startDate, endDate) {
  const differenceInMilliseconds = endDate.getTime() - startDate.getTime();
  const differenceInMinutes = differenceInMilliseconds / (1000 * 60);
  const hours = Math.floor(differenceInMinutes / 60);
  const minutes = Math.floor(differenceInMinutes % 60);

  let timeDifference = "";

  if (hours > 0) {
    timeDifference += `${hours} hours`;
  }

  if (minutes > 0) {
    if (hours > 0) {
      timeDifference += " and ";
    }
    timeDifference += `${minutes} minutes`;
  }

  if (hours === 0 && minutes === 0) {
    timeDifference = "0 minutes";
  }

  return timeDifference;
}

/**
 * Checks if two potential dates are equal
 * @param {string | Date | object} a
 * @param {string | Date | object} b
 * @returns {boolean}
 */
export const areEqualDates = (a, b) =>
  cleanDate(a).getTime() === cleanDate(b).getTime();

/**
 * Check if a Yogaclass is active
 * @param {object extends {end:Date}} c Yogaclass objec
 * @returns {boolean} true if the end date is yet
 */
export const isActive = (c) => {
  return cleanDate(c.end).getTime() > Date.now();
};

/**
 * Convert a number to currency format
 * @param {number} amount
 * @returns {string} Formatted amount to currency (usd)
 */
export const toCurrency = (amount) =>
  Number(amount)?.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });

/**
 * THe password to supply to unlock the  website
 */
export const WEBSITE_PASSWORD = "guido";
