import MyRadio from './MyRadio'
import Input from './Input'
import Select from './Select'
import CheckBox from './CheckBox'
import DateTimePicker, { DatePicker } from './DatePicker'
import Button from './Button'
import ActionButton from './ActionButton'
import PasswordInput from './PasswordInput'

const Controls = {
  MyRadio,
  Input,
  Select,
  CheckBox,
  DatePicker,
  DateTimePicker,
  Button,
  ActionButton,
  PasswordInput,
}
export default Controls
