import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import { StateProvider } from './store/StateProvider'
import { initialState, reducer } from './store/reducer'
import { QueryClientProvider } from 'react-query'
import queryClient from './lib/queryClient'
import { ThemeProvider } from '@mui/material'
import defaultTheme from './themes/default'

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <StateProvider initialState={initialState} reducer={reducer}>
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={defaultTheme}>
        <App />
      </ThemeProvider>
    </QueryClientProvider>
  </StateProvider>
)
