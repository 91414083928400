import { Box, TableBody, TableCell, TableRow } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Controls from '../../components/controls/Controls'
import { Search } from '@mui/icons-material'
import useTable from '../../hooks/useTable'
import DashboardLayout from '../../layouts/DashboardLayout'
import { useGET } from '../../hooks/api/useGET.hook'
import Loading from '../../components/loading/Loading'
import AddPass from '../../components/modals/AddPass'
import UpdatePass from '../../components/modals/UpdatePass'

const headCells = [
  { id: 'name', label: 'Name' },
  { id: 'description', label: 'Description' },
  { id: 'price', label: 'Price' },
  { id: 'usages', label: 'Usages' },
  { id: 'actions', label: 'Actions', disableSort: true },
]

function Passes() {
  const [passes, setPasses] = useState([])
  const [filter, setFilter] = useState({ fn: (items) => items })
  const { data, isLoading } = useGET('pass-types', 'getAllPassTypes')
  useEffect(() => {
    setPasses(data || [])
  }, [data])
  const handleSearch = (e) => {
    let query = e.target.value?.toLowerCase()
    setFilter({
      fn: (items) =>
        query
          ? items.filter(
              (item) =>
                item.name?.toLowerCase().includes(query) ||
                item.description?.toLowerCase().includes(query) ||
                String(item.price)?.toLowerCase().includes(query)
            )
          : items,
    })
  }

  const { TableContainer, TblHead, TblPagination, recordsAfterPagination } =
    useTable(passes, headCells, filter)
  return (
    <DashboardLayout>
      <Box p={2}>
        {isLoading && <Loading />}
        <Box display={'flex'} gap={2} width={'100%'}>
          <Controls.Input
            label={'Search Pass Bundles'}
            changeFxn={handleSearch}
            InputProps={{ startAdornment: <Search /> }}
            style={{ flex: '1', maxWidth: '530px' }}
          />
          <AddPass />
        </Box>

        <Box mt={2} p={2}>
          <TableContainer>
            <TblHead />
            <TableBody>
              {recordsAfterPagination().map((e, i) => (
                <TableRow key={i + 1 + e.id}>
                  <TableCell>{e.name}</TableCell>
                  <TableCell>{e.description}</TableCell>
                  <TableCell>
                    {Number(e.price)?.toLocaleString('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    })}
                  </TableCell>
                  <TableCell>{e.usages}</TableCell>
                  <TableCell>
                    <UpdatePass pass={e} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </TableContainer>
          <TblPagination />
        </Box>
      </Box>
    </DashboardLayout>
  )
}

export default Passes
