import PassForm from '../forms/PassForm'
import UpdateResourceButton from './UpdateResourceButton'

function UpdatePass({ pass }) {
  return (
    <UpdateResourceButton
      title={'Update Pass'}
      FormComponent={PassForm}
      componetProps={{ pass }}
    />
  )
}

export default UpdatePass
