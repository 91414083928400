import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import AdminDrawer from "../components/Navbar/AdminDrawer";
import { useStateValue } from "../store/StateProvider";

function DashboardLayout(props) {
  const { window, children } = props;
  const [{ drawerWidth, showDrawer }, dispatch] = useStateValue();

  const container =
    window !== undefined ? () => window().document.body : undefined;

  useEffect(() => {
    dispatch({ type: "SET_DRAWER_WIDTH", data: 240 });
    return () => dispatch({ type: "SET_DRAWER_WIDTH", data: 0 });
  }, [dispatch]);

  return (
    <Box sx={{ display: "flex", height: "100%" }}>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={showDrawer}
          onClose={() => dispatch({ type: "TOGGLE_DRAWER" })}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          <AdminDrawer />
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          <AdminDrawer />
        </Drawer>
      </Box>
      <Box
        component="main"
        className="outlet-parent"
        sx={{
          flexGrow: 1,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          minHeight: "calc(100dvh - 80px)",
          p: 2,
          backgroundColor: "#fffff",
          "& > .MuiBox-root": {
            backgroundColor: "#FFFFFF",
            width: "100%",
            height: "100%",
          },
        }}
      >
        {children}
      </Box>
    </Box>
  );
}

export default DashboardLayout;
