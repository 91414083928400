import React, { useState } from 'react'
import Popup from '../Popup'
import { IconButton, Tooltip } from '@mui/material'
import { EditOutlined } from '@mui/icons-material'

function UpdateResourceButton({ FormComponent, title, componetProps = {} }) {
  const [open, setOpen] = useState(false)
  return (
    <>
      <Popup open={open} setOpen={setOpen} title={title}>
        <FormComponent
          {...componetProps}
          successCallback={() => setOpen(false)}
        />
      </Popup>
      <Tooltip title='Update'>
        <IconButton
          variant='contained'
          size='small'
          onClick={() => {
            setOpen(true)
          }}
        >
          <EditOutlined />
        </IconButton>
      </Tooltip>
    </>
  )
}

export default UpdateResourceButton
