import React, { useState } from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import './calendar2.css'
import esLocale from '@fullcalendar/core/locales/es'
import { useStateValue } from '../../store/StateProvider'
import { useNavigate } from 'react-router-dom'
import { cleanDate, isActive } from '../../lib/utils'
import { format } from 'date-fns'
import { toast } from 'react-toastify'
import { Box } from '@mui/material'

export function Calendar2() {
  const [selectedRoom, setSelectedRoom] = useState('CLASES')
  const [selectedCoach, setSelectedCoach] = useState('TODXS')
  const { classes, coaches } = useStateValue()[0]
  const navigate = useNavigate()
  const allEvents = [
    ...classes.map((c) => ({
      ...c,
      date: cleanDate(c.start),
      start: undefined,
      end: undefined,
    })),
  ]
  const coachName = (id) => coaches.find((c) => c.id === id)?.name

  const filteredEvents = allEvents.filter((event) => {
    return (
      (event.room === selectedRoom || selectedRoom === 'CLASES') &&
      (event.coach === selectedCoach || selectedCoach === 'TODXS')
    )
  })

  const handleRoomChange = (newRoom) => {
    setSelectedRoom(newRoom)
  }

  const handleCoachChange = (newCoach) => {
    setSelectedCoach(newCoach)
  }

  const rooms = [
    'CLASES',
    ...new Set(allEvents.map((event) => event.room.toUpperCase())),
  ]
  const coachIds = [...new Set(allEvents.map((event) => event.coach))]

  return (
    <div className='bg-white mt-[10rem] flex flex-col gap-10'>
      <div className='type-class'>{selectedRoom}</div>
      <div className='flex flex-col items-center mt-2'>
        {' '}
        {/* Center the dropdown using flex utilities */}
        {/* Dropdown menu */}
        <Box
          width={'100%'}
          display={'flex'}
          justifyContent={'center'}
          gap={2}
          alignItems={'center'}
        >
          <div className='relative'>
            <select
              value={selectedRoom}
              onChange={(e) => handleRoomChange(e.target.value)}
              className='dropdown'
            >
              {rooms.map((room) => (
                <option key={room} value={room}>
                  {room}
                </option>
              ))}
            </select>
          </div>
          <div>
            <select
              value={selectedCoach}
              onChange={(e) => handleCoachChange(e.target.value)}
              className='dropdown'
            >
              <option value='TODXS'>TODXS</option>
              {coachIds.map((coach, i) => (
                <option key={coach + i} value={coach}>
                  {coachName(coach)}
                </option>
              ))}
            </select>
          </div>
        </Box>
      </div>
      <section className='flex flex-col items-center justify-center w-full h-full'>
        <div
          className='tableheader'
          style={{ marginBottom: '10px', marginTop: '10px' }}
        >
          <FullCalendar
            plugins={[dayGridPlugin]}
            initialView='dayGridWeek'
            weekends={true}
            events={filteredEvents.map((ev) => ({
              ...ev,
              coach: coachName(ev.coach),
            }))}
            height={'560px'}
            eventContent={(eventInfo) =>
              renderEventContent(eventInfo, () =>
                navigate(`/class/${eventInfo.event.id}`)
              )
            }
            dayHeaderFormat={{ weekday: 'short', day: 'numeric' }}
            locales={[esLocale]}
            headerToolbar={{
              left: '',
              center: 'title',
              right: '',
            }}
          />
        </div>
      </section>
    </div>
  )
}

function renderEventContent(eventInfo, gotoReservePage) {
  const startDate = new Date(
    eventInfo.event.date || eventInfo.event.start || eventInfo.event.startDate
  )
  const active = isActive(eventInfo.event._instance.range)

  return (
    <div
      onClick={() =>
        active ? gotoReservePage() : toast.info('Class is Closed')
      }
      style={{ cursor: 'pointer' }}
    >
      <div className={`flex flex-col  items-center`}>
        <span className='flex text-black text-[11px]'>
          {eventInfo.event.title}
        </span>
        <span className='flex text-black text-[12px] font-bold'>
          {eventInfo.event.extendedProps.coach}
        </span>
        <b className='flex text-black text-[11px]'>
          {format(startDate, 'hh:mm a')}
        </b>
        <span className='flex text-black text-[8px] font-bold'>
          {active ? 'Active' : 'Closed'}
        </span>
      </div>
    </div>
  )
}

export default Calendar2
