import React, { useState } from 'react'
import { Lock } from '@mui/icons-material'
import { updateUserPassword } from '../../firebase/auth'
import { useStateValue } from '../../store/StateProvider'
import { Form, useForm } from '../../hooks/useForm'
import Controls from '../controls/Controls'
import { Box } from '@mui/material'
import { toast } from 'react-toastify'

function UpdatePasswordForm({ close }) {
  const [loading, setLoading] = useState(false)
  const { user: current } = useStateValue()[0]

  const validate = (fields = values) => {
    const temp = { ...errors }
    ;['oldpassword', 'confirmpass', 'password'].forEach((required) => {
      if (required in fields)
        temp[required] = fields[required] ? '' : `${required} is required`
    })
    if ('confirmpass' in fields && 'password' in fields) {
      if (!temp['confirmpass'])
        temp['confirmpass'] =
          fields['confirmpass'] === fields['password']
            ? ''
            : 'password mismatch'
    }
    setErrors({ ...temp })
    if (fields === values) return Object.values(temp).every((x) => x === '')
  }

  const [values, resetForm, errors, setErrors, changeFunction] = useForm(
    {
      oldpassword: '',
      password: '',
      confirmpass: '',
    },
    validate,
    true
  )

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (!validate()) return
    let success = false
    setLoading(true)
    try {
      const [user, message] = await updateUserPassword(
        values.oldpassword,
        values.password,
        current
      )
      if (!user) {
        toast.error(message)
      } else {
        success = true
      }
    } catch (error) {
      toast.error(error.message)
    } finally {
      setLoading(false)
      if (success) {
        resetForm()
        close()
      }
    }
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Controls.Input
        label={'Old Password'}
        type='password'
        name='oldpassword'
        value={values.oldpassword}
        changeFxn={changeFunction}
        error={errors.oldpassword}
        InputProps={{ startAdornment: <Lock /> }}
      />
      <Controls.Input
        label={'New Password'}
        type='password'
        name='password'
        value={values.password}
        changeFxn={changeFunction}
        error={errors.password}
        InputProps={{ startAdornment: <Lock /> }}
      />
      <Controls.Input
        label={'Old Password'}
        type='password'
        name='confirmpass'
        value={values.confirmpass}
        changeFxn={changeFunction}
        error={errors.confirmpass}
        InputProps={{ startAdornment: <Lock /> }}
      />
      <Box display='flex' gap={2}>
        <Controls.Button
          disabled={loading}
          variant={''}
          text={loading ? 'updating..' : 'update'}
          type='submit'
        />
        {/* <Controls.Button /> */}
      </Box>
    </Form>
  )
}

export default UpdatePasswordForm
