import React from 'react'
import logo from '../../assets/contornomock1.png'
import logo2 from '../../assets/contornomock2.png'
import './hero.css'

const Hero = () => {
  return (
    <section className='flex flex-col items-center justify-center bg-white h-screen'>
      <div className='flex'>
        <img alt='logo1' src={logo}></img>
        <img alt='logo2' src={logo2}></img>
        {/* <h1 className='text-black text-[200px]'> contorno</h1> */}
      </div>
    </section>
  )
}

export default Hero
