import React, { useState } from 'react'
import Popup from '../Popup'
import { Button } from '@mui/material'
import { AddCircle } from '@mui/icons-material'

function CreateResourceButton({ FormComponent, title }) {
  const [open, setOpen] = useState(false)
  return (
    <>
      <Popup open={open} setOpen={setOpen} title={title}>
        <FormComponent successCallback={() => setOpen(false)} />
      </Popup>
      <Button
        variant='contained'
        size='small'
        sx={{
          minWidth: { xs: '100px', md: '180px' },
        }}
        startIcon={<AddCircle />}
        onClick={() => {
          setOpen(true)
        }}
      >
        New
      </Button>
    </>
  )
}

export default CreateResourceButton
