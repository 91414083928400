import React, { useState } from 'react'
import Controls from '../controls/Controls'
import Popup from '../Popup'
import UpdatePasswordForm from '../forms/UpdatePasswordForm'

function UpdatePassword() {
  const [open, setOpen] = useState(false)
  return (
    <>
      <Popup open={open} setOpen={setOpen} title={'Update Password'}>
        <UpdatePasswordForm close={() => setOpen(false)} />
      </Popup>
      <Controls.Button
        text={'Update Password'}
        variant={'contained'}
        onClick={() => {
          setOpen(true)
        }}
      />
    </>
  )
}

export default UpdatePassword
