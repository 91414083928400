import { Box, Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useStateValue } from '../../store/StateProvider'
import { formatDate } from '@fullcalendar/core'
import { isActive } from '../../lib/utils'
import Popup from '../../components/Popup'
import ReservationDetails from '../../components/ReservationDetails'

function UserClasses() {
  const { userDetails } = useStateValue()[0]
  const [myActiveClasses, setMyActiveClasses] = useState([])
  const [myPastClasses, setMyPastClasses] = useState([])
  const [selected, setSelected] = useState(null)

  useEffect(() => {
    const myClasses = userDetails?.reservations?.map((r) => r.yClass)
    setMyActiveClasses(myClasses.filter(isActive))
    setMyPastClasses(myClasses.filter((c) => !isActive(c)))
  }, [userDetails])

  return (
    <Box display={'flex'} flexDirection={'column'} p={2}>
      <Popup open={!!selected} setOpen={setSelected} title={'Class Record'}>
        {selected && <ReservationDetails reservation={selected} />}
      </Popup>
      <Typography variant='h3'>My Clasess</Typography>
      <Box>
        <Typography variant='h5'>Upcoming</Typography>
        <Grid container width={'100%'} gap={2} p={2}>
          {!myActiveClasses.length && (
            <Typography fontStyle={'italic'}>
              You have no upcoming classes
            </Typography>
          )}
          {myActiveClasses.map((c, i) => (
            <Grid item xs={6} md={4} lg={3} key={i + 1}>
              <div
                style={{ cursor: 'pointer' }}
                onClick={() =>
                  setSelected(
                    userDetails?.reservations?.find((r) => r.yClass.id === c.id)
                  )
                }
                className={`flex flex-col  items-center fc-event ${c.room} `}
              >
                <span className='flex text-black text-[11px]'>{c.title}</span>
                <span className='flex text-black text-[12px] font-bold'>
                  {c.extendedProps?.coach}
                </span>
                <b className='flex text-black text-[11px]'>
                  {formatDate(c.start)}
                </b>
              </div>
            </Grid>
          ))}
        </Grid>
      </Box>

      <Box>
        <Typography variant='h5'>Past Classes</Typography>
        <Grid container width={'100%'} gap={2} p={2}>
          {!myPastClasses.length && (
            <Typography fontStyle={'italic'}>No reservation history</Typography>
          )}
          {myPastClasses.map((c, i) => (
            <Grid item xs={6} md={4} lg={3} key={i + 1}>
              <div
                style={{ cursor: 'pointer' }}
                onClick={() =>
                  setSelected(
                    userDetails?.reservations?.find((r) => r.yClass.id === c.id)
                  )
                }
                className={`flex flex-col  items-center fc-event ${c.room} `}
              >
                <span className='flex text-black text-[11px]'>{c.title}</span>
                <span className='flex text-black text-[12px] font-bold'>
                  {c.extendedProps?.coach}
                </span>
                <b className='flex text-black text-[11px]'>
                  {formatDate(c.start)}
                </b>
              </div>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  )
}

export default UserClasses
