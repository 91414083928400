import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore/lite'

import { getAuth } from 'firebase/auth'

/**
 * Firebase Configueration Details
 */
const firebaseConfig = {
  apiKey: 'AIzaSyCEC_zYaDCNjLE59vTEs-vXrwDaLGCF_Uk',
  authDomain: 'contorno-pilates.firebaseapp.com',
  projectId: 'contorno-pilates',
  storageBucket: 'contorno-pilates.appspot.com',
  messagingSenderId: '1040611803422',
  appId: '1:1040611803422:web:9c9fb448e17a20632a090f',
  measurementId: 'G-GF4Q5X9HQ6',
}

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig)

/**
 * Firestore DB Object
 */
export const db = getFirestore(firebaseApp)

/**
 * Firebase Authentication Object
 */
export const auth = getAuth(firebaseApp)

/**
 * Determines if the app is at development environment
 *
 */
export const devEnv = true
