import { Grid, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useState } from 'react'
import { useGET } from '../hooks/api/useGET.hook'
import PayWithStripe from '../components/modals/PayWithStripe'
import { Elements } from '@stripe/react-stripe-js'
import stripePromise from '../lib/stripe'
import Loading from '../components/loading/Loading'

function BuyPasses() {
  const [selected, setSelected] = useState()
  const [amount, setAmount] = useState(0)
  const { data: passes, isLoading } = useGET('pass-types', 'getAllPassTypes')

  return (
    <Box
      width={'100%'}
      maxWidth={'1400px'}
      mx='auto'
      minHeight={'calc(100dvh - 80px)'}
      display={'flex'}
      p={5}
      gap={1}
      flexDirection={'column'}
      alignItems={'center'}
    >
      {isLoading && <Loading />}
      <Typography
        variant='h4'
        textAlign={'center'}
        fontWeight={'bold'}
        fontSize={'large'}
      >
        Buy Passes
      </Typography>

      <Typography>Choose Bundle</Typography>

      <Grid
        my={2}
        container
        gap={1.5}
        minHeight={'200px'}
        justifyContent={'center'}
      >
        {passes?.map((pass, i) => (
          <Grid
            item
            lg={4}
            md={6}
            xs={12}
            key={i}
            width={'250px'}
            height={'150px'}
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'center'}
            alignItems={'center'}
            borderRadius={4}
            sx={{
              cursor: 'pointer',
            }}
            bgcolor={'#F5BEBE'}
            border={pass.id === selected ? 'solid 2px darkgreen' : 'none'}
            onClick={() => {
              setSelected((prev) => (prev === pass.id ? '' : pass.id))
              setAmount(pass.price)
            }}
          >
            <Typography>{pass.name}</Typography>
            <Typography>{pass.description}</Typography>
            <Typography>Units - {pass.usages}</Typography>
            <Typography
              sx={{
                fontFamily: 'sans-serif',
                fontSize: '16px',
                lineHeight: '1.5',
                textAlign: 'right',
                fontWeight: 'bold',
              }}
            >
              {Number(pass.price)?.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
              })}
            </Typography>
          </Grid>
        ))}
      </Grid>

      {selected && (
        <Elements stripe={stripePromise}>
          <PayWithStripe
            amount={amount}
            getIntentUrl={`/pass-types/${selected}/buy`}
            callBackUrl={`/pass-types/${selected}/buy-callback/`}
            redirectUrl={'/dashboard/profile'}
          />
        </Elements>
      )}
    </Box>
  )
}

export default BuyPasses
