import React from "react";
import DashboardLayout from "../layouts/DashboardLayout";
import { Avatar, Box, Typography } from "@mui/material";
import { useStateValue } from "../store/StateProvider";
import { CardGiftcard, Email, Person } from "@mui/icons-material";
import Controls from "../components/controls/Controls";
import { useNavigate } from "react-router-dom";
import UpdatePassword from "../components/modals/UpdatePassword";

function Profile() {
  const { user } = useStateValue()[0];

  const navigate = useNavigate();

  return (
    <DashboardLayout>
      <Box
        p={2}
        display={"flex"}
        flexDirection={"column"}
        gap={5}
        alignItems="center"
      >
        <Typography my={2} fontSize="4rem">
          My Profile
        </Typography>

        <Box display={"flex"} gap={2} mb={1}>
          <Avatar sx={{ width: "180px", height: "180px" }} src={user?.image} />
        </Box>
        <Box display={"flex"} gap={2} mb={1}>
          <Box
            display={"flex"}
            color={"#555"}
            fontWeight={"bold"}
            fontSize="1rem"
            gap={0.5}
          >
            <Email sx={{ fontSize: "2rem" }} />
            <span className="text-[1rem]">Email</span>
          </Box>
          <Typography>{user?.email}</Typography>
        </Box>
        <Box
          display={"flex"}
          gap={2}
          mb={1}
          fontSize="1rem"
          alignItems="center"
        >
          <Box
            display={"flex"}
            color={"#555"}
            fontWeight={"bold"}
            gap={0.5}
            alignItems="center"
          >
            <Person sx={{ fontSize: "2rem" }} />
            <span>Name</span>
          </Box>
          <Typography>{user?.name}</Typography>
        </Box>
        <Box display={"flex"} gap={2} mb={1} maxHeight={"fit-content"}>
          <Box display={"flex"} color={"#555"} fontWeight={"bold"} gap={0.5}>
            <CardGiftcard />
            <span>Passes</span>
          </Box>
          <Typography>{user?.passes}</Typography>
        </Box>

        <Box display={"flex"} gap={2}>
          <Controls.Button
            text={"Buy Passes"}
            variant={"contained"}
            onClick={() => {
              navigate("/buy-pass");
            }}
          />

          <UpdatePassword />
        </Box>
      </Box>
    </DashboardLayout>
  );
}

export default Profile;
