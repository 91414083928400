import React from 'react'
import {
  Typography,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
} from '@mui/material'

import useStyles from './styles'
import { toCurrency } from '../../../lib/utils'

const CartItem = ({ item, withEdit = true, updateCartItem, loading }) => {
  const classes = useStyles()

  return (
    <Card>
      <CardMedia image={item.image} alt={item.name} className='h-[130px]' />
      <CardContent className='flex flex-col gap-1'>
        <Typography
          className='flex justify-between'
          variant='h5'
          fontSize={'22px'}
        >
          <span>{item.name}</span>
          <span>{item.quantity} Pc(s)</span>
        </Typography>
        <div className='flex justify-between'>
          <Typography variant='h6' fontSize={'14px'}>
            <b className='w-[50%]'>Price:</b> {toCurrency(item.price)}
          </Typography>
          <Typography variant='h6' fontSize={'14px'}>
            <b className='w-[50%]'>Sub total:</b>{' '}
            {toCurrency(item.price * item.quantity)}
          </Typography>
        </div>
      </CardContent>
      {withEdit && (
        <CardActions className={classes.cartActions}>
          <div className={classes.buttons}>
            <Button
              type='button'
              size='small'
              disabled={loading}
              onClick={() => updateCartItem(item).reduceQuantity()}
            >
              -
            </Button>
            <Typography>{item.quantity}</Typography>
            <Button
              type='button'
              disabled={loading}
              size='small'
              onClick={() => updateCartItem(item).addQuantity()}
            >
              +
            </Button>
          </div>
          <Button
            variant='contained'
            type='button'
            disabled={loading}
            color='secondary'
            onClick={() => updateCartItem(item).remove()}
          >
            Remove
          </Button>
        </CardActions>
      )}
    </Card>
  )
}

export default CartItem
