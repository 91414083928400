import ProductForm from '../forms/ProductForm'
import CreateResourceButton from './CreateResourceButton'

function AddProduct() {
  return (
    <CreateResourceButton title={'New Product'} FormComponent={ProductForm} />
  )
}

export default AddProduct
