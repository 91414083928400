import React from 'react'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Divider from '@mui/material/Divider'
import { Toolbar } from '@mui/material'
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined'
import SelfImprovementOutlinedIcon from '@mui/icons-material/SelfImprovementOutlined'
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined'
import ShoppingBasketOutlinedIcon from '@mui/icons-material/ShoppingBasketOutlined'
import { useNavigate } from 'react-router-dom'
import { useStateValue } from '../../store/StateProvider'
import {
  CardGiftcard,
  Person2,
  ProductionQuantityLimitsRounded,
} from '@mui/icons-material'

function AdminDrawer() {
  const { user } = useStateValue()[0]
  const navs = {
    user: [
      {
        path: '',
        icon: <GridViewOutlinedIcon />,
        label: 'Dashboard',
      },
      {
        path: 'classes',
        icon: <SelfImprovementOutlinedIcon />,
        label: 'My Classes',
      },
      {
        path: 'my-orders',
        icon: <ShoppingBasketOutlinedIcon />,
        label: 'My Orders',
      },
      {
        path: 'profile',
        icon: <Person2 />,
        label: 'Profile',
      },
    ],
    admin: [
      {
        path: 'coaches',
        icon: <PeopleAltOutlinedIcon />,
        label: 'Manage Coaches',
      },
      {
        path: 'users',
        icon: <PeopleAltOutlinedIcon />,
        label: 'Manage Users',
      },
      {
        path: 'classes',
        icon: <SelfImprovementOutlinedIcon />,
        label: 'Manage Classes',
      },
      {
        path: 'Passes',
        icon: <CardGiftcard />,
        label: 'Manage Passes',
      },
      {
        path: 'Products',
        icon: <ProductionQuantityLimitsRounded />,
        label: 'Manage Products',
      },
      {
        path: 'orders',
        icon: <ShoppingBasketOutlinedIcon />,
        label: 'Manage Orders',
      },
    ],
  }
  const navigate = useNavigate()
  return (
    <div>
      <Toolbar sx={{ height: '79px' }} />
      <Divider />
      <List>
        {navs.user.map((nav, index) => (
          <ListItem key={index + 1} disablePadding>
            <ListItemButton onClick={() => navigate(`/dashboard/${nav.path}`)}>
              <ListItemIcon>{nav.icon}</ListItemIcon>
              <ListItemText primary={nav.label} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      {user?.role === 'admin' && (
        <div className='my-2'>
          <Divider>Admin</Divider>
          <List>
            {navs.admin.map((nav, index) => (
              <ListItem key={index + 1} disablePadding>
                <ListItemButton
                  onClick={() => navigate(`/dashboard/admin/${nav.path}`)}
                >
                  <ListItemIcon>{nav.icon}</ListItemIcon>
                  <ListItemText primary={nav.label} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </div>
      )}
    </div>
  )
}

export default AdminDrawer
