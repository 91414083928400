import { Button } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'

function Back({ size = 'small' }) {
  const navigate = useNavigate()
  return (
    <>
      <Button size={size} onClick={() => navigate(-1)}>
        Back
      </Button>
    </>
  )
}

export default Back
