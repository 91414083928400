import React, { useEffect, useState } from 'react'
import { Box, Grid, Typography } from '@mui/material'
import { useStateValue } from '../../store/StateProvider'
import { formatDate } from '@fullcalendar/core'
import { isActive } from '../../lib/utils'
import { useNavigate } from 'react-router-dom'

function CoachClasses() {
  const { classes, user: coach } = useStateValue()[0]
  const navigate = useNavigate()
  const [distribution, setDistribution] = useState({ upcoming: [], past: [] })

  useEffect(() => {
    const coachClasses = classes.filter((c) => c.coach === coach.id)
    setDistribution({
      upcoming: coachClasses.filter(isActive),
      past: coachClasses.filter((c) => !isActive(c)),
    })
  }, [classes, coach])
  return (
    <Box display={'flex'} flexDirection={'column'} p={2} gap={2}>
      <Typography variant='h4'>Clasess I am taking</Typography>
      <Box>
        <Typography variant='h5'>Upcoming</Typography>
        <Grid container width={'100%'} gap={2} p={2}>
          {!distribution.upcoming.length && (
            <Typography fontStyle={'italic'}>
              You have no upcoming classes
            </Typography>
          )}
          {distribution.upcoming.map((c, i) => (
            <Grid
              item
              xs={6}
              md={4}
              lg={3}
              key={i + 1}
              sx={{ cursor: 'pointer' }}
              onClick={() =>
                navigate(`/dashboard/classes/${c.id}/reservations`)
              }
            >
              <div
                className={`flex flex-col  items-center fc-event ${c.room} `}
              >
                <span className='flex text-black text-[12px]'>{c.title}</span>
                <b className='flex text-black text-[11px]'>
                  Starting - {formatDate(c.start)}
                </b>
              </div>
            </Grid>
          ))}
        </Grid>
      </Box>

      <Box>
        <Typography variant='h5'>Past Classes</Typography>

        <Grid container width={'100%'} gap={2} p={2}>
          {!distribution.past.length && (
            <Typography fontStyle={'italic'}>
              You have no past classes
            </Typography>
          )}
          {distribution.past.map((c, i) => (
            <Grid
              item
              xs={6}
              md={4}
              sx={{ cursor: 'pointer' }}
              lg={3}
              key={i + 1}
              onClick={() =>
                navigate(`/dashboard/classes/${c.id}/reservations`)
              }
            >
              <div
                className={`flex flex-col  items-center fc-event ${c.room} `}
              >
                <span className='flex text-black text-[12px]'>{c.title}</span>
                <b className='flex text-black text-[11px]'>
                  Ended - {formatDate(c.start)}
                </b>
              </div>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  )
}

export default CoachClasses
