import React from 'react'
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  IconButton,
} from '@mui/material'
import { AddShoppingCart } from '@mui/icons-material'

import useStyles from './styles'
import { usePATCH } from '../../../hooks/api/usePATCH.hook'
import { useStateValue } from '../../../store/StateProvider'

const Product = ({ product }) => {
  const [{ user }, dispatch] = useStateValue()

  const { mutate, isLoading } = usePATCH(
    'users/me',
    (data) => {
      dispatch({ type: 'UPDATE_USER', data })
    },
    'Added to Cart Successfully'
  )
  const addToCart = () => {
    if (isLoading) return
    const cart = user?.cart || []
    const existInCart = cart.find((x) => x.product === product.id)
    const newCart = existInCart
      ? cart
      : [...cart, { product: product.id, quantity: 1 }]
    if (!existInCart) {
      dispatch({ type: 'UPDATE_USER', data: { ...user, cart: newCart } })
      mutate({ cart: newCart })
    }
  }
  const classes = useStyles()
  return (
    <Card className={classes.root}>
      <CardMedia
        className={classes.media}
        image={product.image}
        title={product.name}
      />
      <CardContent>
        <div className={classes.cardContent}>
          <Typography variant='h5' gutterBottom>
            {product.name}
          </Typography>
          <Typography variant='h5'>
            {Number(product.price)?.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
            })}
          </Typography>
        </div>
        <Typography variant='body2' color='textSecondary'>
          {product.description}
        </Typography>
      </CardContent>
      <div className='flex w-[100%] justify-center'>
        <IconButton
          aria-label='Add to Cart'
          onClick={addToCart}
          disabled={isLoading}
        >
          <AddShoppingCart />
        </IconButton>
        {/* <Button> Reservar </Button> */}
      </div>
    </Card>
  )
}

export default Product
