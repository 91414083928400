import { format } from 'date-fns'
import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import { cleanDate } from '../lib/utils'
import { useStateValue } from '../store/StateProvider'

function ReservationDetails({ reservation }) {
  const { coaches } = useStateValue()[0]
  const coachName = (id) => coaches.find((c) => c.id === id)?.name
  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      alignItems={'left'}
      gap={2}
      maxWidth={'800px'}
      sx={{ minWidth: { xs: '280px', md: '500px' } }}
      bgcolor={'#D9D9D9'}
      p={2}
    >
      <Typography variant='h5' textAlign={'center'}>
        Class rservation Details
      </Typography>
      <Box display='flex' sx={{ flexDirection: { xs: 'column', md: 'row' } }}>
        <Typography fontWeight={'bold'} flex='0.3'>
          Class:
        </Typography>
        <Typography flex='0.7'>{reservation?.yClass?.title}</Typography>
      </Box>
      <Box display='flex' sx={{ flexDirection: { xs: 'column', md: 'row' } }}>
        <Typography fontWeight={'bold'} flex='0.3'>
          Time:
        </Typography>
        <Typography flex='0.7'>
          {format(cleanDate(reservation?.yClass.start), 'MM/dd/yyyy hh:mm')}
        </Typography>
      </Box>
      <Box display='flex' sx={{ flexDirection: { xs: 'column', md: 'row' } }}>
        <Typography fontWeight={'bold'} flex='0.3'>
          Coach:
        </Typography>
        <Typography flex='0.7'>
          {coachName(reservation?.yClass?.coach)}
        </Typography>
      </Box>
      <Box display='flex' sx={{ flexDirection: { xs: 'column', md: 'row' } }}>
        <Typography fontWeight={'bold'} flex='0.3'>
          Space Reserved:
        </Typography>
        <Typography flex='0.7'>{reservation?.bed}</Typography>
      </Box>
      <Box display='flex' sx={{ flexDirection: { xs: 'column', md: 'row' } }}>
        <Typography fontWeight={'bold'} flex='0.3'>
          Date Reserved:
        </Typography>
        <Typography flex='0.7'>
          {format(cleanDate(reservation?.createdAt), 'MM/dd/yyyy hh:mm')}
        </Typography>
      </Box>
      <Box display='flex' sx={{ flexDirection: { xs: 'column', md: 'row' } }}>
        <Typography fontWeight={'bold'} flex='0.3'>
          Class Range:
        </Typography>
        <Typography flex='0.7'>
          {format(cleanDate(reservation?.yClass?.start), 'MM/dd/yy hh:mm a')} --{' '}
          {format(cleanDate(reservation?.yClass?.end), 'MM/dd/yy hh:mm a')}
        </Typography>
      </Box>

      <Box mt={2} display={'flex'} justifyContent={'space-around'}>
        <Button variant='contained'>Print</Button>
      </Box>
    </Box>
  )
}

export default ReservationDetails
