import React, { useEffect, useState } from 'react'
import Products from './components/products/Products'
import Navbar from './components/Navbar/Navbar'
import Cart from './components/Cart/Cart'
import {
  Outlet,
  Route,
  BrowserRouter as Router,
  Routes,
} from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css'
import './styles.css'
import Calendar2 from './components/Calendar2/calendar2'
import { useStateValue } from './store/StateProvider'
import { userChanged } from './firebase/auth'
import { ToastContainer } from 'react-toastify'
import { useGET } from './hooks/api/useGET.hook'
import ProtectedRoute from './components/ProtectedRoute'
import CssBaseline from '@mui/material/CssBaseline'
import { Box } from '@mui/material'
import Reserve from './pages/Reserve'
import { auth } from './firebase/_config'
import Classes from './pages/Classes'
import Dashboard from './pages/Dashboard'
import Users from './pages/admin/Users'
import Profile from './pages/Profile'
import Passes from './pages/admin/Passes'
import BuyPasses from './pages/BuyPasses'
import Coaches from './pages/admin/Coaches'
import ClassReservations from './pages/admin/ClassReservations'
import AdminProducts from './pages/admin/AdminProducts'
import AdminClasses from './pages/admin/AdminClasses'
import Checkout from './pages/Checkout'
import { Elements } from '@stripe/react-stripe-js'
import stripePromise from './lib/stripe'
import MyOrders from './pages/user/MyOrders'
import AdminOrders from './pages/admin/AdminOrders'
import { WEBSITE_PASSWORD } from './lib/utils'
import PasswordPage from './components/PasswordPage'

const App = () => {
  const dispatch = useStateValue()[1]
  const [password, setPassword] = useState('')
  const { data: classes } = useGET('classes', 'getAllClasses')
  const { data: coaches } = useGET('users/coaches', 'getAllCoaches')
  const { data: products } = useGET('products', 'getAllProducts')
  const { data: userDetails, refetch: refetchUserDetails } = useGET(
    'users/me',
    'userDetails',
    !!auth.currentUser
  )

  useEffect(() => {
    const unsubscribe = userChanged((user) => {
      if (user) {
        refetchUserDetails()
      }
    })
    return () => unsubscribe()
  }, [])

  useEffect(() => {
    dispatch({ type: 'SET_USER', data: userDetails })
  }, [userDetails, dispatch])

  useEffect(() => {
    dispatch({ type: 'SET_CLASSES', data: classes || [] })
    dispatch({ type: 'SET_COACHES', data: coaches || [] })
    dispatch({ type: 'SET_PRODUCTS', data: products || [] })
  }, [classes, coaches, products, dispatch])

  /**
   * Restrict Homepage for a temporary hardcoded password
   */
  if (password !== WEBSITE_PASSWORD)
    return <PasswordPage setPassword={setPassword} />

  return (
    <Box
      width={'100dvw'}
      minHeight={'100dvh'}
      display={'flex'}
      flexDirection={'column'}
    >
      <ToastContainer
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {/* <Calendar/> */}

      <Router>
        <Navbar />
        <Routes>
          <Route path='/' element={<Products />}></Route>
          <Route path='/cart' element={<Cart />}></Route>
          <Route path='/calendar2' element={<Calendar2 />}></Route>
          <Route path='/class/:id' element={<Reserve />} />
          <Route path='/buy-pass' element={<BuyPasses />} />
          <Route
            path='/checkout'
            element={
              <Elements stripe={stripePromise}>
                <Checkout />
              </Elements>
            }
          />
          <Route
            path='/dashboard'
            element={
              <ProtectedRoute>
                <Outlet />
              </ProtectedRoute>
            }
          >
            <Route path='profile' element={<Profile />} />
            <Route path='classes' element={<Classes />} />
            <Route path='my-orders' element={<MyOrders />} />
            <Route index element={<Dashboard />} />

            {/* Admin Only Endpoints */}
            <Route path='admin/users' element={<Users />} />
            <Route path='admin/classes' element={<AdminClasses />} />
            <Route path='admin/coaches' element={<Coaches />} />
            <Route path='admin/products' element={<AdminProducts />} />
            <Route path='admin/orders' element={<AdminOrders />} />
            <Route
              path='admin/classes/:id/reservations'
              element={<ClassReservations />}
            />
            <Route path='admin/passes' element={<Passes />} />
          </Route>
        </Routes>
      </Router>

      <CssBaseline />
    </Box>
  )
}

export default App
