import UserForm from '../forms/UserForm'
import UpdateResourceButton from './UpdateResourceButton'

function UpdateCoach({ user }) {
  return (
    <UpdateResourceButton
      title={'Update Coach'}
      FormComponent={UserForm}
      componetProps={{ user }}
    />
  )
}

export default UpdateCoach
