import { makeStyles } from '@mui/styles'
import React, { useState } from 'react'
import Loading from '../components/loading/Loading'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1.2),
    position: 'relative',
    '& .MuiFormControl-root': {
      // width: '85%',
      // margin: theme.spacing(1),
    },
    '& .MuiSvgIcon-root': {
      marginRight: theme.spacing(1),
    },
  },
}))

/**
 *
 * @param {object} initialValues
 * @param {boolean} validateOnChange
 * @param {(prop:object)=>void} validateFunction
 * @returns
 */
export function useForm(
  initialValues,
  validateFunction,
  validateOnChange = false
) {
  const [values, setValues] = useState(initialValues)
  const [errors, setErrors] = useState({})

  const changeFunction = (e) => {
    const { name, value } = e.target
    if (name.includes('.')) {
      const [object, property] = name.split('.')
      setValues({
        ...values,
        [object]: { ...values[object], [property]: value },
      })
    } else setValues({ ...values, [name]: value })
    if (validateOnChange && validateFunction)
      validateFunction({ [name]: value })
  }

  const resetForm = () => {
    setValues(initialValues)
    setErrors({})
  }
  return [values, resetForm, errors, setErrors, changeFunction, setValues]
}

export const Form = ({ children, ref, onSubmit, style = {}, ...others }) => {
  const classes = useStyles()
  const [submitting, setSubmitting] = useState(false)

  const handleSubmit = (e) => {
    if (submitting) return
    setSubmitting(true)
    e.preventDefault()
    onSubmit(e)
    setSubmitting(false)
  }
  return (
    <form
      action=''
      className={classes.root}
      autoComplete='off'
      onSubmit={handleSubmit}
      encType='multipart/form-data'
      {...others}
      ref={ref}
      style={{ width: '100%', position: 'relative', ...style }}
    >
      {submitting && <Loading />}
      {children}
    </form>
  )
}
