import YogaClassForm from '../forms/YogaClassForm'
import UpdateResourceButton from './UpdateResourceButton'

function UpdateYogaClass({ yogaClass }) {
  return (
    <UpdateResourceButton
      title={'Update Yoga Class'}
      FormComponent={YogaClassForm}
      componetProps={{ yogaClass }}
    />
  )
}

export default UpdateYogaClass
