import PassForm from '../forms/PassForm'
import CreateResourceButton from './CreateResourceButton'

function AddPass() {
  return (
    <CreateResourceButton title={'New Pass bundle'} FormComponent={PassForm} />
  )
}

export default AddPass
