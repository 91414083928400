import React, { useState } from 'react'
import Popup from '../Popup'
import { Box, Button, Link, Typography } from '@mui/material'
import { Form, useForm } from '../../hooks/useForm'
import Controls from '../controls/Controls'
import { useStateValue } from '../../store/StateProvider'
import { createUser, resetPassword, signIn } from '../../firebase/auth'
import { toast } from 'react-toastify'
import { makeStyles } from '@mui/styles'

const useClasses = makeStyles((theme) => ({
  linksContainer: {
    width: '100%',
    marginTop: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& .MuiTypography-root': {
      fontSize: 'small',
    },
    '& .MuiLink-root': {
      textDecoration: 'none',
      fontStyle: 'italic',
      '&:hover': {
        textDecoration: 'underline',
        cursor: 'pointer',
        color: 'red',
      },
    },
  },
}))

function LoginForm() {
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [type, setType] = useState('login')
  const dispatch = useStateValue()[1]
  const classes = useClasses()

  const validate = (fields = values) => {
    const temp = { ...errors }
    if ('email' in fields)
      temp.email = fields.email
        ? fields.email.includes('@')
          ? ''
          : 'invalid email'
        : ''
    if ('password' in fields && type !== 'reset')
      temp.password = fields.password ? '' : 'password is required'
    if (type === 'signup') {
      if ('name' in fields) temp.name = fields.name ? '' : 'name is required'
      if ('confirmpass' in fields)
        temp.confirmpass = fields.confirmpass
          ? fields.confirmpass === fields.password
            ? ''
            : 'password mismatch'
          : 'confirm your password'
    }

    setErrors({ ...temp })
    // alert(Object.values(temp))
    if (fields === values) return Object.values(temp).every((x) => x === '')
  }

  const [values, resetForm, errors, setErrors, changeFunction] = useForm(
    { email: '', password: '', name: '', confirmpass: '' },
    validate,
    true
  )

  const handleSubmit = async (e) => {
    if (!validate()) return

    setLoading(true)
    try {
      const fn =
        type === 'login'
          ? signIn
          : type === 'reset'
            ? resetPassword
            : createUser
      const [user, message] = await fn(
        values.email,
        values.password,
        values.name
      )

      if (user) {
        setOpen(false)
        toast.success(message)
        dispatch({ type: 'SET_USER', data: user })
      } else {
        toast.error(message.split('auth/')[1]?.split(': Firebase')[0])
      }
    } catch (error) {
      console.log(error)
      toast.error(error.message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <Popup open={open} setOpen={setOpen} title={'Login Form'}>
        <Form
          className='loginForm'
          style={{ maxWidth: '380px', margin: '0px auto' }}
          onSubmit={handleSubmit}
        >
          <Box display={'flex'} flexDirection={'column'} gap={1.5}>
            <Controls.Input
              changeFxn={changeFunction}
              label={'Email'}
              name={'email'}
              value={values.email}
              error={errors.email}
            />
            {type !== 'reset' && (
              <>
                <Controls.PasswordInput
                  changeFxn={changeFunction}
                  label={'Password'}
                  name={'password'}
                  type='password'
                  value={values.password}
                  error={errors.password}
                />
                {type === 'signup' && (
                  <>
                    <Controls.PasswordInput
                      changeFxn={changeFunction}
                      label={'Confirm Password'}
                      name={'confirmpass'}
                      type='password'
                      value={values.confirmpass}
                      error={errors.confirmpass}
                    />
                    <Controls.Input
                      changeFxn={changeFunction}
                      label={'Name'}
                      name={'name'}
                      value={values.name}
                      error={errors.name}
                    />
                  </>
                )}
              </>
            )}
          </Box>
          <Box className={classes.linksContainer}>
            <Controls.Button
              text={loading ? 'submitting...' : type}
              onClick={() => {}}
              disabled={loading}
              size='medium'
              type='submit'
              color={'primary'}
            />
            <Typography
              onClick={() => setType(type === 'reset' ? 'login' : 'reset')}
            >
              <Link className='linkItem'>
                {type === 'reset' ? 'Back to login' : 'forgot password'}
              </Link>
            </Typography>
          </Box>
          {type !== 'reset' && (
            <Box className={classes.linksContainer}>
              <Typography
                pl={1}
                onClick={() => {
                  setType({ login: 'signup', signup: 'login' }[type])
                  resetForm()
                }}
              >
                <Link className='linkItem'>
                  {type === 'login' ? 'signup' : 'login'}
                </Link>
              </Typography>
            </Box>
          )}
        </Form>
      </Popup>
      <Button
        variant='outlined'
        onClick={() => {
          resetForm()
          setType('login')
          setOpen(true)
        }}
      >
        Login
      </Button>
    </>
  )
}

export default LoginForm
