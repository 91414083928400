import { Box, TableBody, TableCell, TableRow } from '@mui/material'
import React, { useState } from 'react'
import Controls from '../../components/controls/Controls'
import { Search } from '@mui/icons-material'
import useTable from '../../hooks/useTable'
import { useStateValue } from '../../store/StateProvider'
import DashboardLayout from '../../layouts/DashboardLayout'
import { cleanDate } from '../../lib/utils'
import UpdateCoach from '../../components/modals/UpdateCoach'
import AddCoach from '../../components/modals/AddCoach'
import ProtectedRoute from '../../components/ProtectedRoute'

const headCells = [
  { id: 'email', label: 'Email' },
  { id: 'name', label: 'Name' },
  { id: 'classes', label: 'Assigned Classes' },
  { id: 'actions', label: 'Actions', disableSort: true },
]

function Coaches() {
  const { coaches, classes } = useStateValue()[0]
  const [filter, setFilter] = useState({ fn: (items) => items })

  const assignedClasses = (id) =>
    classes.filter(
      (c) => cleanDate(c.end).getTime() > Date.now() && c.coach === id
    ).length

  const handleSearch = (e) => {
    let query = e.target.value?.toLowerCase()
    setFilter({
      fn: (items) =>
        query
          ? items.filter((item) => item.name?.toLowerCase().includes(query))
          : items,
    })
  }

  const { TableContainer, TblHead, TblPagination, recordsAfterPagination } =
    useTable(coaches, headCells, filter)
  return (
    <ProtectedRoute restrictedTo={['admin']}>
      <DashboardLayout>
        <Box p={2}>
          <Box display={'flex'} gap={2} width={'100%'}>
            <Controls.Input
              label={'Search Classes'}
              changeFxn={handleSearch}
              InputProps={{ startAdornment: <Search /> }}
              style={{ flex: '1', maxWidth: '530px' }}
            />
            <AddCoach />
          </Box>

          <Box mt={2} p={2}>
            <TableContainer>
              <TblHead />
              <TableBody>
                {recordsAfterPagination().map((e, i) => (
                  <TableRow key={i + 1}>
                    <TableCell>{e.email}</TableCell>
                    <TableCell>{e.name}</TableCell>
                    <TableCell>{assignedClasses(e.id)}</TableCell>
                    <TableCell>
                      <UpdateCoach user={e} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </TableContainer>
            <TblPagination />
          </Box>
        </Box>
      </DashboardLayout>
    </ProtectedRoute>
  )
}

export default Coaches
