import YogaClassForm from '../forms/YogaClassForm'
import CreateResourceButton from './CreateResourceButton'

function AddYogaClass() {
  return (
    <CreateResourceButton
      title={'New Yoga Class'}
      FormComponent={YogaClassForm}
    />
  )
}

export default AddYogaClass
